import React from "react";

import Slider from "./Containers/Slider";
import CardContainer from "./Containers/CardsContainer";
import HelpContainer from "./Containers/HelpContainer";
import ExperienceContainer from "./Containers/ExperienceContainer";

import ReviewsContainer from "./Containers/ReviewsContainer";
import LogoCarousel from "./Containers/LogoCarousel";
import CallBackContainer from "./Containers/CallBackContainer";

import QuoteContainer from "./Containers/QuoteContainer";
import NewsContainer from "./Containers/NewsContainer";
import MapContainer from "./Containers/Mapcontainer";

//json data
import MainCarousel from "../Json/MainCarousel";
import Cards from "../Json/Cards";
import HelpContainerdata from "../Json/HelpContainerdata";
import Experiencedata from "../Json/Experiencedata";
import Reviewdata from "../Json/Reviewdata";
import LogoCarouselData from "../Json/LogoCarouselData";
import Callbackdata from "../Json/Callbackdata";
import Quotedata from "../Json/Quotedata";
import Newsdata from "../Json/Newsdata";
import Mapdata from "../Json/Mapdata";

import { makeStyles } from "@material-ui/core/styles";
import { useParams, useLocation } from "react-router-dom";
import { pushtohistory } from "../redux/action/historyActions";
import { useDispatch } from "react-redux";
import SeoComponent from "../Components/Seo/SeoComponent";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

//
const Index = (props) => {
  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);
  // const { stub } = useParams();

  // React.useEffect(() => {
  //   if (stub) {
  //     props.setStub(stub);
  //   }
  // }, []);

  // const {
  //   MainCarousel,
  //   Cards,
  //   HelpContainerdata,
  //   Experiencedata,
  //   Reviewdata,
  //   LogoCarouselData,
  //   Callbackdata,

  //   Quotedata,
  //   Newsdata,
  //   Mapdata,
  // } = HomePage;

  //

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SeoComponent />
      <Slider slides={MainCarousel} />
      <CardContainer data={Cards} />
      <HelpContainer data={HelpContainerdata} />
      <ExperienceContainer data={Experiencedata} />
      <ReviewsContainer data={Reviewdata} />
      <LogoCarousel data={LogoCarouselData} />
      <CallBackContainer data={Callbackdata} />

      <QuoteContainer data={Quotedata} />
      <NewsContainer data={Newsdata} />
      <MapContainer data={Mapdata} />
    </div>
  );
};

export default Index;
