import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "transparent",
    alignItems: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },

  cover: {
    width: "80%",
    height: "15vh",
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  blueTetx: {
    color: theme.palette.darkBlue,
    fontSize: "1.5rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  blackTetx: {
    marginTop: "1vh",
    color: theme.palette.text.secondary,
    fontSize: ".9rem",
    fontWeight: 400,
  },
}));

export default function HelpCard({ data }) {
  const classes = useStyles();

  const { title, description, imageSrc } = data;

  return (
    <Card className={classes.root} elevation={window.innerWidth < 1000 ? 1 : 0}>
      <CardMedia className={classes.cover} image={imageSrc} />

      <CardContent className={classes.content}>
        <Typography component="h5" variant="h5" className={classes.blueTetx}>
          {title}
        </Typography>
        <Typography className={classes.blackTetx}>{description}</Typography>
      </CardContent>
    </Card>
  );
}
