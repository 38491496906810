import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  CardActions,
  Box,
  Button,
  Container,
  Divider,
  Paper,
} from "@material-ui/core";
import RightArrow from "@material-ui/icons/ArrowRightAlt";
import { Link, withRouter, useParams, useLocation } from "react-router-dom";

import Axios from "axios";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import LockIcon from "@material-ui/icons/Lock";
import UnlockIcon from "@material-ui/icons/Check";
import { useDispatch, useSelector } from "react-redux";
import { openSecuredContactForm } from "../redux/action/utilActions";
import { pushtohistory } from "../redux/action/historyActions";
import SEO from "@americanexpress/react-seo";
import SeoComponent from "../Components/Seo/SeoComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "5vh 0vw",
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },

  blogImg: {
    width: "100%",
    // backgroundColor: "red",
    objectFit: "cover",
    height: "50vh",
  },
  blogContentContainer: {
    flex: 5,
  },
  TagsIcon: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
  tag: {
    display: "inline",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.info.light,
    borderRadius: 7,
    margin: "1vh 0.5vw",
    fontSize: 12,
  },
  tagsContainer: {
    display: "flex",
    alignItems: "center",
  },
  post: {
    // whiteSpace: "wrap",
    // overflow: "hidden",
    width: "100%",

    // textOverflow: "ellipsis",
  },
  socialContainer: {
    display: "flex",
    width: "10vw",

    alignItems: "center",
    justifyContent: "space-between",
  },
  socialIcon: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  relatedTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: "pointer",
  },
  filepath: {
    textDecoration: "none",
  },
  check: {
    fontSize: 25,
    color: "green",
    marginRight: theme.spacing(1),
  },
  lock: {
    fontSize: 25,
    color: "red",
    marginRight: theme.spacing(1),
  },
}));

const LayoutBlog = (props) => {
  const classes = useStyles();
  const [data, setData] = React.useState(null);
  let { title } = useParams();

  React.useEffect(() => {
    Axios.get(`/data/HowTo.json`).then((res) => {
      // console.log(res.data);
      // console.log(res.data);
      setData(res.data.howtos.find((item) => item.urlcode === title));
      console.log(res.data, "howto");
    });
  }, [title]);

  const handleRelatedClick = (id) => {
    window.scrollTo({
      top: 0,

      behavior: "smooth",
    });
    props.history.push(`/howto/${id}`);
  };

  // const handleDownloadClick = (filepath) => {
  //   const link = document.createElement("a");
  //   link.href = filepath;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);

  return (
    <div className={classes.root}>
      <SeoComponent
        title={data?.title}
        description={data?.description}
        keywords={data?.keywords}
        image={data?.img}
      />
      <Container maxWidth="md">
        {data !== null && data !== undefined && (
          <Grid container justify="space-evenly" spacing={2}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              container
              alignItems="center"
            >
              <Typography variant="h4" color="primary">
                {data.title}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <Typography variant="body1">
                {ReactHtmlParser(data.description)}
              </Typography>
            </Grid>
            {data.filepath && (
              <Grid item lg={12} md={12} xs={12} sm={12}>
                <a
                  href={data.filepath}
                  className={classes.filepath}
                  target="_blank"
                  download
                >
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={<RightArrow />}
                  >
                    Download Now
                  </Button>
                </a>
              </Grid>
            )}
            {data.related && (
              <Grid item lg={12} md={12} xs={12} sm={12}>
                <Typography variant="h6">Related Topics</Typography>
              </Grid>
            )}
            <Grid item lg={12} md={12} xs={12} sm={12}>
              {data.related &&
                data.related.map((item, index) => {
                  return (
                    <div key={index}>
                      <Typography
                        variant="body1"
                        color="secondary"
                        className={classes.relatedTitle}
                        onClick={() => handleRelatedClick(item.title)}
                      >
                        {item.title}
                      </Typography>
                      <Divider />
                    </div>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default withRouter(LayoutBlog);
