import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import MainFooter from "./MainFooter";
import BottomFotter from "./BottomFooter";
const useStyles = makeStyles((theme) => ({
  main: {
    height: "60vh",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  mainFooter: {
    backgroundColor: theme.palette.darkBlue,
    height: "50vh",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  BottomFotter: {
    height: "10vh",
    backgroundColor: theme.palette.pink,
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
}));
const Footer = ({ data }) => {
  const classes = useStyles();

  const { section1, section2, section3, bottomFooter } = data;

  return (
    <div className={classes.main}>
      <div className={classes.mainFooter}>
        <MainFooter
          section1={section1}
          section2={section2}
          section3={section3}
        />
      </div>
      <div className={classes.BottomFotter}>
        <BottomFotter data={bottomFooter} />
      </div>
    </div>
  );
};
export default Footer;
