import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  ExpansionPanel,
  Button,
  Divider,
  Modal,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  CardActions,
  Hidden,
} from "@material-ui/core";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { Link, withRouter } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { CloudDownload, FormatListBulleted } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { openSecuredContactForm } from "../redux/action/utilActions";
const useStyles = makeStyles((theme) => ({
  root: {},
  usecaseTitle: {
    fontWeight: 600,
  },
  featureTitle: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  listItem: {
    margin: theme.spacing(1),
  },
  img: {
    objectFit: "contain",
    width: "100%",
    height: "20vh",
  },
  modalRoot: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardRoot: {
    height: "80%",
    width: "90%",
    overflow: "auto",
  },
  modalImg: {
    width: "100%",
    objectFit: "contain",
  },
  mobileContainer: {
    width: "90vw",
  },
  solContainer: {
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: 10,
    marginBottom: theme.spacing(2),
    borderColor: theme.palette.grey[500],
  },
  list: {
    backgroundColor: theme.palette.action.hover,
  },
  CardHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "2rem",
    color: theme.palette.primary.main,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  date: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
  },
  contentsList: {
    marginTop: theme.spacing(2),
  },
  description: {
    // marginTop: theme.spacing(2),
  },
  orderedList: {
    marginLeft: -theme.spacing(2),
  },
  orderedListItem: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  filepath: {
    textDecoration: "none",
  },
}));
const initmodalstate = {
  open: false,
  imgpath: "",
};
const Layout3 = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [currentData, setCurrentData] = React.useState();
  const { data } = props;
  const [modalOpen, setModalOpen] = React.useState(initmodalstate);

  React.useEffect(() => {
    setCurrentData(data?.data[0]);
  }, []);

  const formSubmitted = useSelector((state) => state.util.formSubmitted);

  const handleNavigate = (filePath) => {
    let failpath = "/category/NewsLetters";
    if (formSubmitted) {
      const link = document.createElement("a");
      link.href = filePath;
      link.type = "download";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      dispatch(openSecuredContactForm(failpath, failpath));
    }
  };
  return (
    <div>
      <Hidden smDown>
        <Grid container justify="space-evenly" spacing={2}>
          <Grid item lg={9}>
            {currentData && (
              <Card variant="outlined">
                <CardContent>
                  <div className={classes.CardHeaderContainer}>
                    <Typography className={classes.title}>
                      {currentData?.title}
                    </Typography>
                    <Typography className={classes.date}>
                      {currentData?.date}
                    </Typography>
                  </div>
                </CardContent>

                <CardContent>
                  <Typography
                    className={classes.description}
                    variant="body1"
                    paragraph
                  >
                    {currentData?.description}
                  </Typography>
                  <ol className={classes.orderedList}>
                    {currentData?.contents?.map((content, index) => {
                      return (
                        <li>
                          <Typography className={classes.orderedListItem}>
                            {content}
                          </Typography>
                        </li>
                      );
                    })}
                  </ol>
                  <div className={classes.btnContainer}>
                    {/* <a
                      href={currentData?.filepath}
                      className={classes.filepath}
                      target="_blank"
                      download
                    > */}
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => handleNavigate(currentData?.filepath)}
                      disableElevation
                      endIcon={<CloudDownload />}
                    >
                      Download
                    </Button>
                    {/* </a> */}
                  </div>
                  {/* <List className={classes.contentsList}>
                  <ListItem>
                    <ListItemIcon>
                      <FormatListBulleted />
                    </ListItemIcon>
                    <ListItemText primary={"Bullet 1"} />
                  </ListItem>
                </List> */}
                </CardContent>
              </Card>
            )}
          </Grid>

          <Grid item lg={3} md={3} sm={12} xs={12}>
            <List className={classes.list} disablePadding>
              {data.data.length > 0 &&
                data.data.map((item, index) => {
                  return (
                    <>
                      <ListItem
                        button
                        key={index}
                        onClick={() => setCurrentData(item)}
                      >
                        <ListItemText primary={item.title} />
                      </ListItem>
                      <Divider component="li" />
                    </>
                  );
                })}
            </List>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.mobileContainer}>
          {data.data.length > 0 &&
            data.data.map((item, index) => {
              return (
                <>
                  <Accordion variant="outlined">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>
                        {item?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Card variant="elevation" elevation={0}>
                        <CardContent>
                          <div className={classes.CardHeaderContainer}>
                            <Typography className={classes.date}>
                              {item?.date}
                            </Typography>
                          </div>
                        </CardContent>

                        <CardContent>
                          <Typography
                            className={classes.description}
                            variant="body1"
                            paragraph
                          >
                            {item?.description}
                          </Typography>
                          <ol className={classes.orderedList}>
                            {item?.contents?.map((content, index) => {
                              return (
                                <li>
                                  <Typography
                                    className={classes.orderedListItem}
                                  >
                                    {content}
                                  </Typography>
                                </li>
                              );
                            })}
                          </ol>
                          <div className={classes.btnContainer}>
                            {/* <a
                              href={item?.filepath}
                              className={classes.filepath}
                              target="_blank"
                              download
                            > */}
                            <Button
                              color="secondary"
                              variant="contained"
                              disableElevation
                              onClick={() => handleNavigate(item?.filepath)}
                              endIcon={<CloudDownload />}
                            >
                              Download
                            </Button>
                            {/* </a> */}
                          </div>
                          {/* <List className={classes.contentsList}>
                  <ListItem>
                    <ListItemIcon>
                      <FormatListBulleted />
                    </ListItemIcon>
                    <ListItemText primary={"Bullet 1"} />
                  </ListItem>
                </List> */}
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                </>
              );
            })}
        </div>
      </Hidden>
    </div>
  );
};

export default withRouter(Layout3);
