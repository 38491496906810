import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  ExpansionPanel,
  Button,
  Divider,
  Modal,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  CardActions,
} from "@material-ui/core";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { Link, withRouter } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  root: {},
  usecaseTitle: {
    fontWeight: 600,
  },
  featureTitle: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  listItem: {
    margin: theme.spacing(1),
  },
  img: {
    objectFit: "contain",
    width: "100%",
    height: "20vh",
  },
  modalRoot: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardRoot: {
    height: "80%",
    width: "90%",
    overflow: "auto",
  },
  modalImg: {
    width: "100%",
    objectFit: "contain",
  },
  solContainer: {
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: 10,
    marginBottom: theme.spacing(2),
    borderColor: theme.palette.grey[500],
  },
}));
const initmodalstate = {
  open: false,
  imgpath: "",
};
const Layout3 = (props) => {
  const classes = useStyles();
  const { data } = props;
  const [modalOpen, setModalOpen] = React.useState(initmodalstate);
  return (
    <div>
      <Grid container justify="space-evenly" spacing={2}>
        {data.list.length > 0 &&
          data.list.map((item, index) => {
            return (
              <Grid item lg={3} md={3} sm={12} xs={12} key={index}>
                <Card>
                  <CardHeader title={item.name} />
                  <CardContent>{item.content}</CardContent>
                  <CardActions>
                    <Button
                      color="primary"
                      onClick={() => {
                        props.history.push(`/${item.path}`);
                      }}
                    >
                      Learn more
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default withRouter(Layout3);
