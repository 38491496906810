export default {
  title: "GREAT REVIEWS FOR OUR SERVICES",
  subTitle1: "Trusted by some",
  subTitle2: "Biggest Names",
  reviewers: [
    {
      imageSrc:
        "http://avantage.bold-themes.com/business/wp-content/uploads/sites/2/2019/04/img-quote-01.jpg",
      title: "Absolutely spot-on!",
      description:
        "Seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing. Holistically pontificate installed base portals.",
      rating: 4,
      name: "JAMES BRISK",
      organization: "HSBC Bank",
    },
    {
      imageSrc:
        "http://avantage.bold-themes.com/business/wp-content/uploads/sites/2/2019/04/img-quote-02.jpg",
      title: "Best decision ever",
      description:
        "Quickly deploy strategic networks with compelling e-business. Credibly pontificate highly efficient manufactured products and enabled data",
      rating: 2,
      name: "HOWARD MCMILLAN",
      organization: "Hotel Berg",
    },
    {
      imageSrc:
        "http://avantage.bold-themes.com/business/wp-content/uploads/sites/2/2019/04/img-quote-01.jpg",
      title: "Absolutely spot-on!",
      description:
        "Seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing. Holistically pontificate installed base portals.",
      rating: 4,
      name: "JAMES BRISK",
      organization: "HSBC Bank",
    },
  ],
};
