import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Redirect,
  useParams,
} from "react-router-dom";
import "./App.css";
import * as Routes from "./Routes";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import Home from ".//HomePage";
import MainNavBar from "./Json/Navbar";
import Nav from "./Navbar/index";
import TopNavBar from "./Json/TopBar";
import FooterData from "./Json/footer";
import SubCategory from "./SubPages/SubCategory";
import Footer from "./Footer/index";
import BlogPost from "./SubPages/BlogPost";
import { Provider, useDispatch, useSelector } from "react-redux";
// import Services from "./Layouts/Services"
import Store from "./redux/store";
import Axios from "axios";
import {
  setUserLocation,
  getvisitortoken,
  setSubscribeContact,
} from "./redux/action/useractions";
import { pushtohistory, uploadhistory } from "./redux/action/historyActions";
import CaseStudy from "./SubPages/CaseStudy";
import FAQPost from "./SubPages/FAQPost";
import CookieDialog from "./Components/Cookie/CookieDialog";
import ReactInterval from "react-interval";
import NewsLetter from "./Components/Modals/NewsLetter";
import ResourceCategory from "./SubPages/ResourceCategory";
import Articles from "./SubPages/Article";
import HowToPost from "./SubPages/HowToPost";
import SecuredContentForm from "./Components/Modals/SecuredContentForm";
import Aboutus from "./SubPages/Aboutus";
import Contactus from "./SubPages/Contactus";
import { getLocalContact, getLocalSubScribe } from "./redux/utils/utils";
const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,

      main: "#e94d65",

      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // secondary: {
    //   main: "#184158",
    // },
    white: "#FFFfFf",
    darkBlue: "#184158",
    pink: "#e94d65",
    black: "#323232",
    lightGray: "whitesmoke",

    secondary: {
      // light: "#0066ff",
      main: "#184158",
      // dark: will be calculated from palette.secondary.main,
      // contrastText: "#ffcc00",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  status: {
    danger: "orange",
  },
  shape: {
    borderRadius: 0,
  },
});

function App(props) {
  const dispatch = useDispatch();

  const location = useLocation().pathname;

  const history = useSelector((state) => state.history.history);
  const consent = useSelector((state) => state.user.consent);

  const subscribe = useSelector((state) => state.user.subscribe);
  const contact = useSelector((state) => state.user.contact);

  const visitorid = useSelector((state) => state.user.id);
  const email = useSelector((state) => state.history.email);
  const name = useSelector((state) => state.history.name);
  const [stub, setStub] = React.useState(undefined);

  let localSubscribe = getLocalSubScribe();
  let localContact = getLocalContact();

  const handleSubscribeandContact = () => {
    dispatch(
      setSubscribeContact({
        subscribe: localSubscribe ? localSubscribe : false,
        contact: localContact ? localContact : false,
      })
    );
  };
  React.useEffect(() => {
    Axios.get(
      "https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572"
    ).then((res) => {
      dispatch(getvisitortoken(res.data));
      dispatch(setUserLocation(res.data));
    });
    handleSubscribeandContact();
  }, []);

  const uploadhistorytoserver = (visitorid, consent, history) => {
    if (consent && history.length > 0) {
      dispatch(
        uploadhistory({
          id: visitorid,
          userId: null,
          consent: consent,
          logs: history,
          email,
          name,
        })
      );
    }
  };
  return (
    <ThemeProvider theme={theme}>
      {!subscribe && <NewsLetter />}
      <CookieDialog />
      {!contact && <SecuredContentForm />}
      <ReactInterval
        timeout={10000}
        enabled={true}
        callback={() => uploadhistorytoserver(visitorid, consent, history)}
      />

      <Nav topbar={TopNavBar} mainbar={MainNavBar} />
      <Route exact path={Routes.HOME} component={Home} />
      <Route exact path={Routes.SUBCATEGORY} component={SubCategory} />
      <Route exact path={Routes.RESOURCES} component={ResourceCategory} />
      <Route exact path={Routes.BLOGPOST} component={BlogPost} />
      <Route exact path={Routes.CASESTUDY} component={CaseStudy} />
      <Route exact path={Routes.ARTICLES} component={Articles} />
      <Route exact path={Routes.FAQ} component={FAQPost} />
      <Route exact path={Routes.HowTos} component={HowToPost} />
      <Route exact path={Routes.ABOUTUS} component={Aboutus} />
      <Route exact path={Routes.CONTACTUS} component={Contactus} />
      {/* <Route render={() => <Redirect to={Routes.HOME} />} /> */}
      {/* <Route exact path={Routes.SERVICES} component={Services} /> */}

      <Footer data={FooterData} />
    </ThemeProvider>
  );
}

const AppContainer = () => {
  return (
    <Router>
      <Provider store={Store()}>
        <App />
      </Provider>
    </Router>
  );
};
export default AppContainer;
