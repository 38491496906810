import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  pinkBtn: {
    backgroundColor: theme.palette.darkBlue,
    color: theme.palette.white,
    height: "8vh",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.darkBlue,
      color: theme.palette.white,

      boxShadow: "0em .2em .8em rgba(0,0,0,0.3)",
    },
    [theme.breakpoints.down("md")]: {
      height: "5vh",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

export default function BlueButton(props) {
  const classes = useStyles();

  return (
    <Button className={classes.pinkBtn} {...props}>
      {props.children}
    </Button>
  );
}
