import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Hidden,
} from "@material-ui/core";
import PinkButton from "../../../Components/Common/pinkButton";

import CallBackCard from "./CallBackCard";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  main: {
    margin: "15vh 0",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      margin: "5vh 0",
      height: "auto",
    },
  },
  Divider: {
    backgroundColor: theme.palette.pink,
    height: ".1em",
  },
  Title: {
    marginLeft: theme.spacing(3),
    textTransform: "uppercase",
  },
  blueTetx: {
    color: theme.palette.darkBlue,
    fontSize: "2.5rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      fontSize: "1.5rem",
    },
  },
  pinkTetx: {
    color: theme.palette.pink,
    fontSize: "2.5rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
  },
  blackTetx: {
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  img: {
    width: "80%",
  },
}));
const CallBackContainer = (props) => {
  const { data, history } = props;
  const classes = useStyles();
  const {
    mainImageSrc,
    title,
    subTitle1,
    subTitle2,
    description,
    button,
    cards,
  } = data;
  const navigate = (path) => {
    history.push(path);
  };
  return (
    <div className={classes.main}>
      <Container maxWidth="lg">
        <Grid container className={classes.main}>
          <Grid
            item
            container
            alignItems="center"
            lg={6}
            md={12}
            sm={12}
            xs={12}
          >
            <Grid container direction="column" spacing={5}>
              <Grid
                item
                container
                lg={12}
                md={12}
                sm={12}
                xs={12}
                alignItems="center"
              >
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Divider className={classes.Divider} />
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                  <Typography className={classes.Title}>{title}</Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography className={classes.blueTetx}>
                    {subTitle1}
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography className={classes.pinkTetx}>
                    {subTitle2}
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography className={classes.blackTetx}>
                    {description}
                  </Typography>
                </Grid>
              </Grid>
              <Hidden mdDown>
                <Grid item lg={12}>
                  <PinkButton onClick={() => navigate(button.link)}>
                    {button.title}
                  </PinkButton>
                </Grid>
                <Grid item lg={12}>
                  <Divider />
                </Grid>
              </Hidden>
              <Grid item container spacing={3} justify="space-evenly">
                {cards.map((data, index) => (
                  <Grid item container direction="column" lg={3} key={index}>
                    <CallBackCard data={data} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item container justify="center" lg={6}>
              <img src={mainImageSrc} className={classes.img} alt={title} />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default withRouter(CallBackContainer);
