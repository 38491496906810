export default {
  title: "WHERE CAN WE HELP YOU",
  subTitle1: "Consultancy",
  subTitle2: "Industries",
  description:
    "Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line. Override the digital divide with additional clickthroughs from DevOps.",
  cards: [
    {
      title: "Solicitory",
      description:
        "Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.",
      imageSrc: "https://fonts.gstatic.com/s/i/materialicons/alarm/v4/24px.svg",
    },
    {
      title: "Business Planning",
      description:
        "Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.",
      imageSrc:
        "https://fonts.gstatic.com/s/i/materialicons/contactless/v4/24px.svg",
    },
    {
      title: "Human Resources",
      description:
        "Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.",
      imageSrc: "https://fonts.gstatic.com/s/i/materialicons/eco/v4/24px.svg",
    },
    {
      title: "Solicitory",
      description:
        "Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.",
      imageSrc: "https://fonts.gstatic.com/s/i/materialicons/alarm/v4/24px.svg",
    },
    {
      title: "Business Planning",
      description:
        "Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.",
      imageSrc:
        "https://fonts.gstatic.com/s/i/materialicons/contactless/v4/24px.svg",
    },
    {
      title: "Human Resources",
      description:
        "Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.",
      imageSrc: "https://fonts.gstatic.com/s/i/materialicons/eco/v4/24px.svg",
    },
  ],
  button: { title: "Button", link: "/link" },
};
