import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SEO from "@americanexpress/react-seo";
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      backgroundColor: "white",
    },
  },
}));

const SeoComponent = (props) => {
  const classes = useStyles();
  const defaultTitle = "Nuncho Ecommerce ERP Solutions";
  const defaultDescription = "Nuncho Ecommerce ERP Solutions";
  const defaultKeywords = ["ecommerce", "erp", "ecommerce erp"];
  const defualtSiteUrl = window.location.href;
  const defaultImage = "/path-to-logo.png";
  return (
    <div className={classes.root}>
      <SEO
        title={props?.title || defaultTitle}
        description={props?.description || defaultDescription}
        keywords={props?.keywords || defaultKeywords}
        siteUrl={defualtSiteUrl}
        image={{
          src: props?.image || defaultImage,
        }}
      />
    </div>
  );
};
export default SeoComponent;
