import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import validator from "validator";
import { useSelector, useDispatch } from "react-redux";

import {
  closeSecuredContactForm,
  submitContactForm,
} from "../../redux/action/utilActions";
import { useHistory, withRouter } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  card: {
    maxHeight: "95vh",
    overflow: "auto",
    maxWidth: "50vw",
    // width: "80vw",
  },
  captchabg: {},
  captcha: {
    borderRadius: 10,
    backgroundImage: `linear-gradient(45deg, #b3b3b3 25%, #3a7cb3 25%, #3a7cb3 50%, #b3b3b3 50%, #b3b3b3 75%, #3a7cb3 75%, #3a7cb3 100%)`,
    backgroundSize: "28.28px 28.28px",
    padding: "1vh 2vh",
    color: "white",
  },
}));

const ContactForm = () => {
  const history = useHistory();
  const [state, setState] = React.useState({
    name: "",
    companyname: "",
    email: "",
    mobile: "",
    message: "",
    address: "",
    place: "",
  });
  const [captcha, setCaptcha] = React.useState({
    value: "",
    enteredValue: "",
  });
  const [error, setError] = React.useState("");

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    formMessage,
    open,
    requestSuccess,
    successUrl,
    failUrl,
  } = useSelector((state) => state.util);

  const handleChange = (e) => {
    setError("");
    const name = e.target.name;
    const value = e.target.value;

    setState({
      ...state,
      [name]: value,
    });
  };
  const handleCaptchaChange = (e) => {
    e.preventDefault();
    setError("");

    setCaptcha({
      ...captcha,
      enteredValue: e.target.value,
    });
  };
  const handleClose = () => {
    dispatch(closeSecuredContactForm());
    // props.history.goBack();
  };
  React.useEffect(() => {
    function keyGen(keyLength) {
      var i,
        key = "",
        characters =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      var charactersLength = characters.length;
      for (i = 0; i < keyLength; i++) {
        key += characters.substr(
          Math.floor(Math.random() * charactersLength + 1),
          1
        );
      }
      setCaptcha({
        ...captcha,
        value: key,
      });
      return key;
    }
    keyGen(6);
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();

    var mailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // console.log(re.test(this.state.email));
    var mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;

    if (!mailregex.test(state.email)) {
      setError("email");
    } else if (!mobileRegex.test(state.mobile)) {
      setError("mobile");
    } else if (captcha.value !== captcha.enteredValue) {
      // console.log(captcha, "captcha");
      setError("captcha");
    } else {
      const data = {
        name: state.name,
        email: state.email,
        mobile: state.mobile,
        companyname: state.companyname,
        address: state.address,
        message: state.message,
        place: state.place,
      };
      dispatch(submitContactForm(data));
    }
  };
  const handleRequestFail = () => {
    history.push(`/Resources${failUrl}`);
    handleClose();
  };
  return (
    <form onError={(e) => console.log(e)} onSubmit={(e) => handleSubmit(e)}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            disabled={requestSuccess}
            variant="outlined"
            color="secondary"
            fullWidth
            size="small"
            label="Name"
            required={true}
            name="name"
            value={state.name}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            disabled={requestSuccess}
            variant="outlined"
            color="secondary"
            fullWidth
            size="small"
            label="Company Name"
            required={true}
            name="companyname"
            value={state.companyname}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            disabled={requestSuccess}
            variant="outlined"
            color="secondary"
            fullWidth
            size="small"
            inputMode="email"
            label="Business Email"
            required={true}
            error={error === "email"}
            helperText={error === "email" && "Email is not valid"}
            name="email"
            value={state.email}
            onChange={(e) => handleChange(e)}
          />
        </Grid>{" "}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            disabled={requestSuccess}
            variant="outlined"
            color="secondary"
            fullWidth
            size="small"
            label="Mobile Number"
            inputMode="numeric"
            required={true}
            error={error === "mobile"}
            helperText={error === "mobile" && "Mobile Number is not valid"}
            // type="number"
            name="mobile"
            value={state.mobile}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            disabled={requestSuccess}
            variant="outlined"
            color="secondary"
            fullWidth
            size="small"
            label="Address"
            required={true}
            multiline
            rows="3"
            // type="number"
            name="address"
            value={state.address}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            disabled={requestSuccess}
            variant="outlined"
            color="secondary"
            fullWidth
            size="small"
            label="City, State and Country"
            required={false}
            placeholder="City, State and Country"
            // type="number"
            name="place"
            value={state.place}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            disabled={requestSuccess}
            variant="outlined"
            color="secondary"
            fullWidth
            size="small"
            label="Message"
            multiline
            rows="3"
            required={false}
            placeholder="Message"
            // type="number"
            name="message"
            value={state.message}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
        <Grid item lg={8} md={8} sm={8} xs={8}>
          <TextField
            disabled={requestSuccess}
            variant="outlined"
            color="secondary"
            fullWidth
            label="Enter Captcha"
            required={true}
            // type="number"
            error={error === "captcha"}
            helperText={error === "captcha" && "Captcha does not match"}
            name="captcha"
            value={state.captcha}
            onChange={(e) => handleCaptchaChange(e)}
          />
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          sm={4}
          xs={4}
          container
          alignItems="center"
          justify="center"
          className={classes.captchabg}
        >
          <Typography variant="h6" className={classes.captcha}>
            {captcha.value}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Button
            disabled={requestSuccess}
            fullWidth
            color="primary"
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </Grid>
        {formMessage && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Alert severity={formMessage?.type}>{formMessage?.message}</Alert>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
export default ContactForm;
