import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { Container, Icon, Hidden, Link } from "@material-ui/core";
import ClockIcon from "@material-ui/icons/Schedule";
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import FbIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import PinterestIcon from "@material-ui/icons/Pinterest";
import LinkedinIcon from "@material-ui/icons/LinkedIn";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  AppBar: {
    backgroundColor: theme.palette.darkBlue,
    height: "7vh",
    [theme.breakpoints.down("md")]: {
      height: "7vh",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(1),
    },
  },
  font: {
    marginLeft: "0.3rem",
    fontSize: "1rem",
    objectFit: "cover",
    color: "white",

    [theme.breakpoints.down("md")]: {
      marginLeft: ".3rem",
      fontSize: ".8rem",
    },
  },
  icon: {
    color: theme.palette.pink,
  },
  socialIcon: {
    color: theme.palette.pink,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },

  title: {
    display: "flex",
    alignItems: "center",
    // flexGrow: 1,
  },
  singlenavItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  secondItem: {
    marginLeft: theme.spacing(3),

    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  rightContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexGrow: 1,
  },
  link: {
    color: "white",
    marginRight: theme.spacing(2),
    textDecoration: "none",
  },
}));

export default function TopNav({ data }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.AppBar}>
        <Toolbar variant="dense">
          <div className={classes.rightContent}>
            {/* <Hidden mdDown>
              <Typography className={classes.font}>
                {data.socialText}
              </Typography>
            </Hidden> */}
            <a
              target="_blank"
              href={data.socialLinks.facebook}
              rel="noopener noreferrer"
            >
              <FbIcon className={classes.socialIcon} />
            </a>
            <a
              target="_blank"
              href={data.socialLinks.twitter}
              rel="noopener noreferrer"
            >
              <TwitterIcon className={classes.socialIcon} />
            </a>
            <a
              target="_blank"
              href={data.socialLinks.pinterest}
              rel="noopener noreferrer"
            >
              <PinterestIcon className={classes.socialIcon} />
            </a>
            <a
              target="_blank"
              href={data.socialLinks.linkedIn}
              rel="noopener noreferrer"
            >
              <LinkedinIcon className={classes.socialIcon} />
            </a>
          </div>
          <div className={classes.title}>
            {data?.links?.map((link, index) => {
              return (
                <Link
                  key={index}
                  href={link.link}
                  target="_blank"
                  className={classes.link}
                  style={{ textDecoration: "none" }}
                >
                  <Typography className={classes.font}>
                    {link?.title}
                  </Typography>
                </Link>
              );
            })}
            {/* <div className={classes.singlenavItem}>
              <Icon className={classes.icon}>
                <ClockIcon className={classes.singleicon} />
              </Icon>
              <Typography className={classes.font}>
                {data.officeTime}
              </Typography>
            </div>
            <Hidden mdDown>
              <div className={classes.secondItem}>
                <Icon className={classes.icon}>
                  <LocationIcon className={classes.singleicon} />
                </Icon>
                <Typography className={classes.font}>
                  {data.officeLocation}
                </Typography>
              </div>
            </Hidden> */}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
