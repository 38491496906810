import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  Divider: {
    backgroundColor: theme.palette.pink,
    height: ".1em",
  },
  Title: {
    marginLeft: theme.spacing(3),
    textTransform: "uppercase",
  },
  blueTetx: {
    color: theme.palette.darkBlue,
    fontSize: "2.5rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  pinkTetx: {
    marginLeft: theme.spacing(2),
    color: theme.palette.pink,
    fontSize: "2.5rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
}));
const Dividertext = (props) => {
  const classes = useStyles();
  const { title } = props;
  return (
    <div>
      <Grid container spacing={2} alignItems="center" spacing={1}>
        <Grid item lg={1} md={2} sm={2} xs={2}>
          <Divider className={classes.Divider} />
        </Grid>
        <Grid item lg={10} md={10} sm={10} xs={10}>
          <Typography className={classes.pinkTetx}>{title}</Typography>
        </Grid>
        {props.subTitle1 && (
          <Grid item>
            <Typography className={classes.blueTetx}>
              {props.subTitle1}
            </Typography>
          </Grid>
        )}
        {props.subTitle2 && (
          <Grid item>
            <Typography className={classes.pinkTetx}>
              {props.subTitle2}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
export default Dividertext;
