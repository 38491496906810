import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Typography from "@material-ui/core/Typography";

import { Grid, Divider, Paper } from "@material-ui/core";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundSize: "cover",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },

  content: {
    flex: "1 0 auto",
  },
  Divider: {
    backgroundColor: theme.palette.pink,
    height: ".1em",
  },

  blackTetx: {
    fontSize: "1.5rem",
    color: theme.palette.text.secondary,

    fontWeight: 400,
  },
  blueTetx: {
    color: theme.palette.darkBlue,
    fontSize: "1.1rem",
  },
  secondaryTetx: {
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    fontWeight: 400,
    textDecoration: "none",

    paddingBottom: theme.spacing(1),
  },
  pinkTetx: {
    color: theme.palette.pink,
    fontSize: "1rem",
    cursor: "pointer",
    textTransform: "uppercase",
  },
}));
function MediaControlCard(props) {
  const { data, history } = props;
  const { tag1, tag2, title, description, button } = data;
  const classes = useStyles();
  const navigation = (path) => {
    history.push(path);
  };

  return (
    <Grid item lg={4} md={12} sm={12} xs={12} className={classes.root}>
      <Card elevation={0}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <div className={classes.controls}>
              <Grid container direction="column" spacing={5}>
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.blueTetx}>
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.secondaryTetx}>
                      {description}
                    </Typography>
                  </Grid>
                  <Grid item onClick={() => navigation(button.link)}>
                    <Typography className={classes.pinkTetx}>
                      {button.title}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </CardContent>
        </div>
      </Card>
    </Grid>
  );
}
export default withRouter(MediaControlCard);
