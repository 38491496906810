import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  CardActions,
  Box,
  Button,
  Container,
  Divider,
  Paper,
  TextField,
} from "@material-ui/core";
import RightArrow from "@material-ui/icons/ArrowRightAlt";
import { Link, withRouter, useParams, useLocation } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import SEO from "@americanexpress/react-seo";

import Axios from "axios";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import LockIcon from "@material-ui/icons/Lock";
import UnlockIcon from "@material-ui/icons/Check";
import { useDispatch, useSelector } from "react-redux";
import {
  openSecuredContactForm,
  submitContactForm,
} from "../redux/action/utilActions";
import { pushtohistory } from "../redux/action/historyActions";
import SeoComponent from "../Components/Seo/SeoComponent";
import ContactForm from "../Components/form/ContactForm";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10vh 0vw",
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },

  gridItemContainer: {
    // height: "80vh",
  },
  frame: {
    [theme.breakpoints.down("md")]: {
      height: "50vh",
    },
  },
  captcha: {
    borderRadius: 10,
    backgroundImage: `linear-gradient(45deg, #b3b3b3 25%, #3a7cb3 25%, #3a7cb3 50%, #b3b3b3 50%, #b3b3b3 75%, #3a7cb3 75%, #3a7cb3 100%)`,
    backgroundSize: "28.28px 28.28px",
    padding: "1vh 2vh",
    color: "white",
  },
}));

const LayoutBlog = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    name: "",
    companyname: "",
    email: "",
    phone: "",

    address: "",
    country: "",
  });
  const [captcha, setCaptcha] = React.useState({
    value: "",
    enteredValue: "",
  });
  const [error, setError] = React.useState("");

  const open = useSelector((state) => state.util.securedContactFormOpen);
  const [requestSuccess, setRequestSuccess] = React.useState(null);
  const successUrl = useSelector((state) => state.util.successpath);
  const failUrl = useSelector((state) => state.util.failpath);
  const handleChange = (e) => {
    setError("");
    const name = e.target.name;
    const value = e.target.value;

    setState({
      ...state,
      [name]: value,
    });
  };
  const handleCaptchaChange = (e) => {
    e.preventDefault();
    setError("");

    setCaptcha({
      ...captcha,
      enteredValue: e.target.value,
    });
  };
  // const handleClose = () => {
  //   dispatch(closeSecuredContactForm());
  //   // props.history.goBack();
  // };
  React.useEffect(() => {
    function keyGen(keyLength) {
      var i,
        key = "",
        characters =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      var charactersLength = characters.length;
      for (i = 0; i < keyLength; i++) {
        key += characters.substr(
          Math.floor(Math.random() * charactersLength + 1),
          1
        );
      }
      setCaptcha({
        ...captcha,
        value: key,
      });
      return key;
    }
    keyGen(6);
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();

    var mailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // console.log(re.test(this.state.email));
    var mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;

    if (!mailregex.test(state.email)) {
      setError("email");
    } else if (!mobileRegex.test(state.phone)) {
      setError("phone");
    } else if (captcha.value !== captcha.enteredValue) {
      // console.log(captcha, "captcha");
      setError("captcha");
    } else {
      const data = {
        name: state.name,
        email: state.email,
        phone: state.phone,
        companyname: state.companyname,
        address: state.address,
        country: state.country,
      };

      // Axios req

      Axios.post("url", data)
        .then((res) => setRequestSuccess(true))
        .catch((err) => {
          setRequestSuccess(false);
          setError("requestfail");
        });
    }
  };
  const handleRequestFail = () => {
    // props.history.push(`/Resources${failUrl}`);
  };

  React.useEffect(() => {
    // if (requestSuccess === true) {
    //   props.history.push(successUrl);
    // } else if (requestSuccess === false) {
    //   setError("requestfail");
    //   setTimeout(() => {
    //     handleRequestFail();
    //   }, 5000);
    // handleRequestFail()
    // props.history.push(`/Resouces/${failUrl}`);
    // handleClose();
    // }
  }, [requestSuccess]);
  const [data, setData] = React.useState(null);

  let { title } = useParams();

  React.useEffect(() => {
    Axios.get(`/data/ContactUs.json`).then((res) => {
      // console.log(res.data);
      // console.log(res.data);
      setData(res.data);
    });
  }, []);

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);

  // const handleDownloadClick = (filepath) => {
  //   const link = document.createElement("a");
  //   link.href = filepath;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  return (
    <div className={classes.root}>
      <SeoComponent
        title={data?.title}
        description={data?.description}
        keywords={data?.keywords}
        image={data?.img}
      />
      <Container maxWidth="lg">
        {data !== null && data !== undefined && (
          <Grid container justify="space-evenly" spacing={2}>
            <Grid itel lg={6} className={classes.gridItemContainer}>
              <iframe
                title="map"
                src={data.mapLink}
                width="100%"
                height="100%"
                className={classes.frame}
              ></iframe>
            </Grid>
            <Grid itel lg={6} className={classes.gridItemContainer}>
              <Box m={3}>
                <div className={classes.contactContainer}>
                  <div className={classes.contactDescription}>
                    <Typography variant="body1">
                      {ReactHtmlParser(data.description)}
                    </Typography>
                  </div>
                  <div className={classes.formContainer}>
                    {requestSuccess !== true ? (
                      <ContactForm />
                    ) : (
                      <Alert severity="success">
                        Thank you for Contacting us. Our associate will contact
                        you shortly.
                      </Alert>
                    )}
                  </div>
                </div>
              </Box>
            </Grid>

            {/* <Grid
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              container
              alignItems="center"
            >
              <Typography variant="h2" color="primary">
                {data.title}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <Typography variant="body1">
                {ReactHtmlParser(data.description)}
              </Typography>
            </Grid> */}
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default withRouter(LayoutBlog);
