import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { Grid, ListItemIcon, Paper, Typography } from "@material-ui/core";

import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {},
  gridContainer: {
    // marginTop: theme.spacing(1),
    // padding: theme.spacing(2),
    // width: "60vw",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  linkTitle: {
    color: theme.palette.primary.main,
  },
  title: {
    // color: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    paddingBottom: theme.spacing(1),
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  singleLink: {
    paddingTop: "1vh",
    paddingBottom: "1vh",
    paddingRight: "1vh",

    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    fontSize: "0.9rem",
  },
}));

function SimpleList(props) {
  const classes = useStyles();
  const { title, children } = props?.children;

  const ChooseMainCategory = (e, index) => {
    let text = e.target.innerText;
  };
  const ChooseSubCategory = (e, index, products) => {
    let text = e.target.innerText;
  };
  return (
    <div className={classes.root}>
      {/* <Grid container className={classes.gridContainer} spacing={2}> */}
      {props?.children?.map((child, index) => {
        return (
          <Link
            className={classes.link}
            to={{
              pathname: `/${child.link}`,
              state: child.title,
            }}
          >
            <Typography className={classes.title}>{child?.title}</Typography>
          </Link>
        );
      })}
    </div>
  );
}

export default SimpleList;
