import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import logo from "../Static/logo.png";

import IconButton from "@material-ui/core/IconButton";

import { Badge, Grid, Hidden } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ShopIcon from "@material-ui/icons/ShoppingCart";
import PhoneIcon from "@material-ui/icons/Phone";
import PinkButton from "../Components/Common/pinkButton";
import SubMenu from "./subMenu";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import MobileNav from "./MobileNav";
const useStyles = makeStyles((theme) => ({
  AppBar: {
    backgroundColor: theme.palette.white,
  },
  Container: {
    height: theme.spacing(15),
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(6),
    },
  },
  logo: {
    height: theme.spacing(15),
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(5),
    },
  },
  btn: {
    marginLeft: theme.spacing(2),
  },
}));
const MainNavBar = ({ data }) => {
  const classes = useStyles();
  const { cellNumber, children } = data;

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={classes.AppBar}
        color="default"
        elevation={1}
      >
        <Toolbar>
          <Grid
            container
            className={classes.Container}
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <div className={classes.title}>
                <Link to="/">
                  <img src={logo} className={classes.logo} alt="logo" />
                </Link>
              </div>
            </Grid>
            <Hidden mdDown>
              <Grid item>
                <Grid
                  container
                  spacing={1}
                  justify="space-between"
                  alignItems="center"
                >
                  {children.length > 0 &&
                    children.map((data, index) => (
                      <Grid item key={index}>
                        <SubMenu children={data} key={data.title} />
                      </Grid>
                    ))}
                </Grid>
              </Grid>

              <Grid item>
                <Grid container>
                  <Grid item className={classes.btn}>
                    <PinkButton startIcon={<PhoneIcon />}>
                      {cellNumber}
                    </PinkButton>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <MobileNav children={children} />
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default MainNavBar;

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";

// import logo from "../../../Static/logo.png";

// import IconButton from "@material-ui/core/IconButton";

// import { Container, Badge } from "@material-ui/core";
// import SearchIcon from "@material-ui/icons/Search";
// import ShopIcon from "@material-ui/icons/ShoppingCart";
// import PhoneIcon from "@material-ui/icons/Phone";
// import PinkButton from "../../../Components/Common/pinkButton";

// import SubMenu from "./subMenu";
// import { Link } from "react-router-dom";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   AppBar: {
//     backgroundColor: theme.palette.white,
//     color: theme.palette.black,
//     height: "20vh",
//   },
// logo: {
//   height: "20vh",
// },

//   font: {
//     marginLeft: "0.3rem",
//     fontSize: ".8rem",
//   },
//   icon: {
//     color: theme.palette.pink,
//   },

//   title: {
//     display: "flex",
//     alignItems: "center",
//     flexGrow: 1,
//   },
//   link: {
//     fontSize: "1rem",
//     fontWeight: 600,
//   },

//   rightContent: {
//     width: "45%",
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-between",
//     alignItems: "center",
//   },
//   endContainer: {
//     width: "20%",
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "flex-end",
//     alignItems: "center",
//   },
//   iconLinks: {
//     color: theme.palette.black,
//   },
//   ShopIcon: {
//     color: theme.palette.black,
//     marginRight: theme.spacing(3),
//   },
//   Link: {
//     textDecoration: "none",
//     color: theme.palette.black,
//   },
// }));

// export default function MainNav({ data }) {
//   const classes = useStyles();
// const { cellNumber, links } = data;

//   return (
// <div className={classes.root}>
//   <AppBar position="static" className={classes.AppBar} elevation={1}>
//     <Container maxWidth="xl">
//       <Toolbar>
// <div className={classes.title}>
//   <Link to="/">
//     <img src={logo} className={classes.logo} alt="logo" />
//   </Link>
// </div>
// <div className={classes.rightContent}>
//   {links.length > 0 &&
//     links.map((data) => <SubMenu data={data} key={data.title} />)}
// </div>
//         <div className={classes.endContainer}>
// <IconButton className={classes.iconLinks}>
//   <SearchIcon />
// </IconButton>
// <IconButton className={classes.ShopIcon}>
//   <Badge color="primary" badgeContent={1}>
//     <ShopIcon />
//   </Badge>
// </IconButton>
// <PinkButton startIcon={<PhoneIcon />}>{cellNumber}</PinkButton>
//         </div>
//       </Toolbar>
//     </Container>
//   </AppBar>
// </div>
//   );
// }
