import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import validator from "validator";
import { useSelector, useDispatch } from "react-redux";

import {
  closeSecuredContactForm,
  submitContactForm,
} from "../../redux/action/utilActions";
import { withRouter } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import ContactForm from "../form/ContactForm";
const useStyles = makeStyles((theme) => ({
  modalRoot: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    maxHeight: "95vh",
    overflow: "auto",
    maxWidth: "50vw",
    // width: "80vw",
  },
  captchabg: {},
  captcha: {
    borderRadius: 10,
    backgroundImage: `linear-gradient(45deg, #b3b3b3 25%, #3a7cb3 25%, #3a7cb3 50%, #b3b3b3 50%, #b3b3b3 75%, #3a7cb3 75%, #3a7cb3 100%)`,
    backgroundSize: "28.28px 28.28px",
    padding: "1vh 2vh",
    color: "white",
  },
}));
const SecuredContentForm = (props) => {
  const [state, setState] = React.useState({
    name: "",
    companyname: "",
    email: "",
    mobile: "",

    address: "",
    place: "",
  });
  const [captcha, setCaptcha] = React.useState({
    value: "",
    enteredValue: "",
  });
  const [error, setError] = React.useState("");

  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.util.securedContactFormOpen);
  const requestSuccess = useSelector((state) => state.util.requestSuccess);
  const successUrl = useSelector((state) => state.util.successpath);
  const failUrl = useSelector((state) => state.util.failpath);
  const handleChange = (e) => {
    setError("");
    const name = e.target.name;
    const value = e.target.value;

    setState({
      ...state,
      [name]: value,
    });
  };
  const handleCaptchaChange = (e) => {
    e.preventDefault();
    setError("");

    setCaptcha({
      ...captcha,
      enteredValue: e.target.value,
    });
  };
  const handleClose = () => {
    dispatch(closeSecuredContactForm());
    // props.history.goBack();
  };
  React.useEffect(() => {
    function keyGen(keyLength) {
      var i,
        key = "",
        characters =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      var charactersLength = characters.length;
      for (i = 0; i < keyLength; i++) {
        key += characters.substr(
          Math.floor(Math.random() * charactersLength + 1),
          1
        );
      }
      setCaptcha({
        ...captcha,
        value: key,
      });
      return key;
    }
    keyGen(6);
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();

    var mailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // console.log(re.test(this.state.email));
    var mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;

    if (!mailregex.test(state.email)) {
      setError("email");
    } else if (!mobileRegex.test(state.mobile)) {
      setError("mobile");
    } else if (captcha.value !== captcha.enteredValue) {
      // console.log(captcha, "captcha");
      setError("captcha");
    } else {
      const data = {
        name: state.name,
        email: state.email,
        mobile: state.mobile,
        companyname: state.companyname,
        address: state.address,
        place: state.place,
      };
      dispatch(submitContactForm(data));
    }
  };
  const handleRequestFail = () => {
    props.history.push(`/Resources${failUrl}`);
    handleClose();
  };

  React.useEffect(() => {
    if (requestSuccess === true) {
      props.history.push(successUrl);
      handleClose();
    } else if (requestSuccess === false) {
      setError("requestfail");
      setTimeout(() => {
        handleRequestFail();
      }, 5000);
      // handleRequestFail()
      // props.history.push(`/Resouces/${failUrl}`);
      // handleClose();
    }
  }, [requestSuccess]);
  return (
    <div>
      <Modal open={open} className={classes.modalRoot}>
        <Card className={classes.card}>
          <CardHeader
            title=" Please Fill this form to access the content"
            titleTypographyProps={{
              style: {
                fontSize: 20,
              },
            }}
            action={
              <IconButton onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            }
          />
          <Divider />

          <CardContent>
            <ContactForm />
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
};

export default withRouter(SecuredContentForm);
