import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Container } from "@material-ui/core";
import Card from "./Card";
const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: "-10vh",
    marginBottom: "10vh",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      marginTop: "3vh",
      marginBototm: "0vh",
      height: "auto",
    },
  },
}));
const Index = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardContainer}>
      <Container maxWidth="lg">
        <Grid container justify="center" alignItems="center" spacing={3}>
          {data.map((data) => (
            <Grid
              key={data.title}
              item
              container
              justify="center"
              alignItems="center"
              lg={4}
              sm={12}
              md={4}
              xs={12}
            >
              <Card data={data} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};
export default Index;
