import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";

import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";

import Typography from "@material-ui/core/Typography";

import { Grid, Divider } from "@material-ui/core";
import PinkButton from "../../../Components/Common/pinkButton";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    zIndex: 500,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  Divider: {
    backgroundColor: theme.palette.pink,
    height: ".1em",
  },
  Title: {
    marginLeft: theme.spacing(3),
    textTransform: "uppercase",
    fontSize: "1rem",
  },
  blueTetx: {
    color: theme.palette.darkBlue,
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  pinkTetx: {
    color: theme.palette.pink,
    fontSize: "4rem",
    fontWeight: 600,
  },
  blackTetx: {
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    fontWeight: 400,
  },
}));

function BusinessCard(props) {
  const classes = useStyles();

  const { data, history } = props;
  const { imageSrc, title, subTitle, description, button } = data;
  const navigate = (path) => {
    history.push(path);
  };

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={imageSrc} title={title} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid
            item
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            alignItems="center"
            spacing={1}
          >
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <Divider className={classes.Divider} />
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Typography className={classes.Title}>{title}</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.blueTetx}>{subTitle}</Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.blackTetx}>
                {description}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            spacing={2}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <PinkButton onClick={() => navigate(button.link)}>
                {button.title}
              </PinkButton>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
export default withRouter(BusinessCard);
