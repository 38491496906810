import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  CardActions,
  Box,
  Button,
  Container,
  Divider,
  Paper,
} from "@material-ui/core";
import TagsIcon from "@material-ui/icons/Loyalty";
import { Link, withRouter, useParams, useLocation } from "react-router-dom";

import Axios from "axios";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import social from "../Static/social";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import RightArrow from "@material-ui/icons/ArrowRightAlt";
import { useDispatch } from "react-redux";
import { openSecuredContactForm } from "../redux/action/utilActions";
import { pushtohistory } from "../redux/action/historyActions";
import { Helmet } from "react-helmet";
import SeoComponent from "../Components/Seo/SeoComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "5vh 0vw",
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },

  blogImg: {
    width: "100%",
    // backgroundColor: "red",
    objectFit: "cover",
    height: "50vh",
  },
  blogContentContainer: {
    flex: 5,
  },
  TagsIcon: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
  tag: {
    display: "inline",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.info.light,
    borderRadius: 7,
    margin: "1vh 0.5vw",
    fontSize: 12,
  },
  tagsContainer: {
    display: "flex",
    alignItems: "center",
  },
  post: {
    // whiteSpace: "wrap",
    // overflow: "hidden",
    width: "100%",

    // textOverflow: "ellipsis",
  },
  socialContainer: {
    display: "flex",
    width: "10vw",

    alignItems: "center",
    justifyContent: "space-between",
  },
  socialIcon: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  relatedTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: "pointer",
  },
  filepath: {
    textDecoration: "none",
  },
}));

const LayoutBlog = (props) => {
  const classes = useStyles();
  const [data, setData] = React.useState(null);
  let { title } = useParams();

  React.useEffect(() => {
    Axios.get(`/data/CaseStudies.json`).then((res) => {
      // console.log(res.data);
      // console.log(res.data);
      setData(res.data.casestudies.find((item) => item.urlcode === title));
      // console.log(
      //   title,
      //   res.data.casestudies.find((item) => item.subject === title)
      // );
    });
  }, []);

  const handleSocialClick = (url) => {
    window.open(url, "_blank");
  };
  const handleRelatedClick = (id) => {
    window.scrollTo({
      top: 0,

      behavior: "smooth",
    });
    props.history.push(`/casestudy/${id}`);
  };

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);

  return (
    <div className={classes.root}>
      <SeoComponent
        title={data?.title}
        description={data?.description}
        keywords={data?.keywords}
        image={data?.img}
      />
      <Container maxWidth="md">
        {data !== null && data !== undefined && (
          <Grid container justify="space-evenly" spacing={2}>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <Typography variant="h4" color="primary">
                {data.title}
              </Typography>
            </Grid>
            {data.images.length > 0 &&
              data.images.map((img, index) => {
                return (
                  <Grid item lg={12} md={12} xs={12} sm={12}>
                    <img src={img} alt={img} className={classes.blogImg} />
                  </Grid>
                );
              })}
            {data.goal && (
              <>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography variant="h5" className={classes.title}>
                    Goal
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography variant="body1">
                    {ReactHtmlParser(data.goal)}
                  </Typography>
                </Grid>
              </>
            )}
            {data.plan && (
              <>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography variant="h5" className={classes.title}>
                    Plan
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography variant="body1">
                    {ReactHtmlParser(data.plan)}
                  </Typography>
                </Grid>
              </>
            )}

            {data.implementation && (
              <>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography variant="h5" className={classes.title}>
                    Implementation
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography variant="body1">
                    {ReactHtmlParser(data.implementation)}
                  </Typography>
                </Grid>
              </>
            )}
            {data.result && (
              <>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography variant="h5" className={classes.title}>
                    Result
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography variant="body1">
                    {ReactHtmlParser(data.result)}
                  </Typography>
                </Grid>
              </>
            )}
            {data.conclusion && (
              <>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography variant="h5" className={classes.title}>
                    Conclusion
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography variant="body1">
                    {ReactHtmlParser(data.conclusion)}
                  </Typography>
                </Grid>
              </>
            )}

            {data.videos && (
              <>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography variant="h5" className={classes.title}>
                    Videos
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  {data.videos &&
                    data.videos.map((video, index) => {
                      return (
                        <iframe
                          style={{
                            width: "100%",
                            height: "60vh",
                            marginBottom: "5vh",
                          }}
                          src={video}
                        />
                      );
                    })}
                </Grid>
              </>
            )}
            {data.filepath && (
              <Grid item lg={12} md={12} xs={12} sm={12}>
                <a
                  href={data.filepath}
                  className={classes.filepath}
                  target="_blank"
                  download
                >
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={<RightArrow />}
                  >
                    Download Now
                  </Button>
                </a>
              </Grid>
            )}

            <Grid item lg={12} md={12} xs={12} sm={12}>
              <Typography variant="h6">Share</Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <div className={classes.socialContainer}>
                <TwitterIcon
                  className={classes.socialIcon}
                  onClick={() => handleSocialClick(social.twitter)}
                />
                <FacebookIcon
                  className={classes.socialIcon}
                  onClick={() => handleSocialClick(social.facebook)}
                />
                <LinkedInIcon
                  className={classes.socialIcon}
                  onClick={() => handleSocialClick(social.linkdedin)}
                />
              </div>
            </Grid>

            {data.related && (
              <>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography variant="h6">Related Case Studies</Typography>
                </Grid>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  {data.related.length > 0 &&
                    data.related.map((blog, index) => {
                      return (
                        <div key={index}>
                          <Typography
                            variant="body1"
                            color="secondary"
                            className={classes.relatedTitle}
                            onClick={() => handleRelatedClick(blog.subject)}
                          >
                            {data.subject}
                          </Typography>
                          <Divider />
                        </div>
                      );
                    })}
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default withRouter(LayoutBlog);
