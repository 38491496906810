import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import LeftArrow from "@material-ui/icons/ChevronLeft";
import RightArrow from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.lightGray,
    height: "50vh",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "20vh",
    },
  },
  logo: {
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  Carousel: {
    height: "interit",
    paddingTop: "10%",
    [theme.breakpoints.down("md")]: {
      paddingTop: "6%",
    },
  },
  arrow: {
    color: theme.palette.darkBlue,
    fontSize: 50,
  },
}));
const LogoCarousel = ({ data }) => {
  const classes = useStyles();

  const { logos } = data;
  return (
    <div className={classes.main}>
      <div className={classes.Carousel}>
        <Carousel
          slidesPerPage={6}
          arrows
          infinite
          arrowLeft={<LeftArrow className={classes.arrow} />}
          arrowRight={<RightArrow className={classes.arrow} />}
          addArrowClickHandler
          offset={0}
          autoPlay={2000}
          breakpoints={{
            1000: {
              slidesPerPage: 1,
              arrows: false,
            },
          }}
        >
          {logos.map((data, index) => (
            <img
              src={data.imageSrc}
              key={index}
              className={classes.logo}
              alt={index}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};
export default LogoCarousel;
