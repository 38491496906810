import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Divider,
  Typography,
  Hidden,
} from "@material-ui/core";
import HelpCard from "./HelpCard";
import BlueButton from "../../../Components/Common/blueButton";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      marginTop: "-10vh",
    },
  },
  Divider: {
    backgroundColor: theme.palette.pink,
    height: ".1em",
  },
  Title: {
    marginLeft: theme.spacing(3),
    textTransform: "uppercase",
  },
  blueTetx: {
    color: theme.palette.darkBlue,
    fontSize: "2rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  pinkTetx: {
    color: theme.palette.pink,
    fontSize: "2rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      marginLeft: theme.spacing(1),
    },
  },
  blackTetx: {
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  cardContainer: {
    [theme.breakpoints.down("md")]: {
      marginTop: "-3vh",
    },
  },
}));
const HelpContainer = (props) => {
  const classes = useStyles();
  const { data, history } = props;

  const { title, subTitle1, subTitle2, description, cards, button } = data;
  const navigate = (path) => {
    history.push(path);
  };
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container direction="column" spacing={5}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid
              container
              className={classes.gridContainer}
              direction="column"
              spacing={5}
            >
              <Grid
                item
                container
                lg={8}
                md={12}
                sm={12}
                xs={12}
                alignItems="center"
              >
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Divider className={classes.Divider} />
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                  <Typography className={classes.Title}>{title}</Typography>
                </Grid>
                <Grid item lg={12} md="auto" sm="auto" xs="auto">
                  <Typography className={classes.blueTetx}>
                    {subTitle1}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={6} sm={6} xs={6}>
                  <Typography className={classes.pinkTetx}>
                    {subTitle2}
                  </Typography>
                </Grid>
                <Grid item lg={8} md={12} sm={12} xs={12}>
                  <Typography className={classes.blackTetx}>
                    {description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.cardContainer}
          >
            <Grid container alignItems="center" justify="center" spacing={3}>
              {cards.map((data, index) => {
                return (
                  <Grid item lg={4} md={12} sm={12} xs={12} key={index}>
                    <HelpCard data={data} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item container justify="center" lg={12}>
              <BlueButton onClick={() => navigate(button.link)}>
                {button.title}
              </BlueButton>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default withRouter(HelpContainer);
