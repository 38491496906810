const MainNavbar = {
  cellNumber: "+91 99725 91911",
  children: [
    {
      title: "Products",
      columns: 2,

      children: [
        {
          title: "Cloud Products",
          children: [
            {
              title: "Human Resources",
              link: "category/HumanResources",
            },
            {
              title: "CRM",
              link: "category/CRM",
            },
            {
              title: "Warehouse Management",
              link: "category/WarehouseManagement",
            },
            {
              title: "Order Management",
              link: "category/OrderManagement",
            },

            {
              title: "Transportation Management",
              link: "category/TransportationManagement",
            },
            {
              title: "Inventory Management",
              link: "category/InventoryManagement",
            },
            {
              title: "Manufacturing Management",
              link: "category/ManufacturingManagement",
            },
 	    {
              title: "Performance Management",
              link: "category/PerformanceManagement",
            },
            {
              title: "Accounting Management",
              link: "category/AccountingManagement",
            },
            {
              title: "Procurement Management",
              link: "category/ProcurementManagement",
            },
            {
              title: "Supply Chain Management",
              link: "category/SupplyChainManagement",
            },

            {
              title: "Project Management",
              link: "category/ProjectManagement",
            },
            {
              title: "Bid Management",
              link: "category/BidManagement",
            },
            {
              title: "WebPOS",
              link: "category/WebPOS",
            },
            {
              title: "Workforce Management",
              link: "category/WorkforceManagement",
            },

            {
              title: "Web Portal Development",
              link: "category/WebPortal",
            },
            {
              title: "Ecommerce Integration",
              link: "category/EcommerceIntegration",
            },      
            {
              title: "Electronic Data Interchange (EDI)",
              link: "category/EDI",
            },     
          ],
        },
        {
          title: "Technology Components",
          children: [
            {
              title: "Security Management",
              link: "category/SecurityManagement",
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            },
            {
              title: "Reports Management",
              link: "category/Reports",
              description:
                "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of ",
            },
            {
              title: "Batch Process Management",
              link: "category/BatchProcess",
            },
            {
              title: "Approvals Management",
              link: "category/Approvals",
            },
            {
              title: "Business Process Management",
              link: "category/BPM",
            },
            {
              title: "Dashboard Management",
              link: "category/Dashboard",
            },
            {
              title: "Employee Self-Service",
              link: "category/EmpSelfService",
            },
            {
              title: "Manager Self-Service",
              link: "category/MgrSelfService",
            },
            {
              title: "Collaboration",
              link: "category/Collaboration",
            },
            {
              title: "Organization Structure",
              link: "category/OrgStructure",
            },
            {
              title: "Data Export",
              link: "category/DataExport",
            },
            {
              title: "Data Import",
              link: "category/DataImport",
            },
            {
              title: "UI Extension",
              link: "category/UIExtension",
            },
            {
              title: "Planning Algorithms",
              link: "category/PlanningAlgorithm",
            },
            {
              title: "Document Management",
              link: "category/Document",
            },
            {
              title: "Documentation Help",
              link: "category/Documentation",
            }
          ],
        },
      ],
    },
    {
      title: "E-Commerce",
      // link: "/Ecommerce",
      columns: 2,

      children: [
        {
          title: "Modules",
          children: [
            {
              title: "Attributes Management",
              link: "category/AttributesManagement",
            },
            {
              title: "Catalog Management",
              link: "category/CatalogManagement",
            },
            {
              title: "Make to Order",
              link: "category/MakeToOrder",
            },
            {
              title: "Configure To Order",
              link: "category/ConfigureToOrder",
            },
            {
              title: "Returns Management",
              link: "category/ReturnsManagement",
            },
            {
              title: "Multi Vendor Marketplace",
              link: "category/MarketPlace",
            },
          ],
        },
        {
          title: "Technology Components",
          children: [
            {
              title: "Security Management",
              link: "category/EcommerceSecurity",
            },
            {
              title: "Administration",
              link: "category/EcommerceAdministration",
            },
            {
              title: "Payment Gateway Integration",
              link: "category/PayGateInt",
            },
            {
              title: "Warehouse Integration",
              link: "category/WarehouseInt",
            },
            {
              title: "Logistics Integration",
              link: "category/LogisticsInt",
            },
          ],
        },
      ],
    },
    {
      title: "Solutions",
      // link: "/Solutions",
      columns: 2,

      children: [
        {
          title: "By Industry",
          children: [
            {
              title: "Automotive",
              link: "category/Automotive",
            },
            {
              title: "Manufacturing",
              link: "category/Manufacturing",
            },
           {
              title: "Pharmaceuticals",
              link: "category/Pharmaceuticals",
            },
            {
              title: "Construction",
              link: "category/Construction",
            },
            {
              title: "Food & Beverages",
              link: "category/FoodBeverages",
            },
            {
              title: "Malls & Recreation",
              link: "category/MallsRecreation",
            },
            {
              title: "Vehicle Rentals",
              link: "category/VehicleRentals",
            },
            {
              title: "Sports Management",
              link: "category/SportsManagement",
            },
            {
              title: "Warehousing & Logistics",
              link: "category/Logistics",
            },
            {
              title: "Publications",
              link: "category/Publications",
            },
            {
              title: "Trading",
              link: "category/Trading",
            },
            {
              title: "Gas Distribution",
              link: "category/GasDistribution",
            },
            {
              title: "Packaging",
              link: "category/Packaging",
            },
            {
              title: "Electronics",
              link: "category/Electronics",
            },
            {
              title: "Retail Management",
              link: "category/Retail",
            },
            {
              title: "Chemical",
              link: "category/Chemical",
            },
            {
              title: "Jewellery",
              link: "category/Jewellery",
            },
            {
              title: "Education",
              link: "category/Education",
            }




          ],
        },
        {
          title: "By Department Managers",
          children: [
            {
              title: "Operations Managers",
              link: "category/OperationsManager",
              description:
                "ERP systems provide operations managers with a comprehensive set of tools and capabilities to address common operational challenges, improve efficiency, and drive business success in today's competitive and fast-paced business environment.",
            },
            {
              title: "Marketing Managers",
              link: "category/MarketingManager",
              description:
                "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of ",
            },
            {
              title: "Sales Managers",
              link: "category/SalesManager",
              description : "ERP systems provide sales managers with the tools and capabilities they need to overcome common sales challenges, improve sales performance, and achieve their revenue targets in today's competitive business environment.",
            },
            {
              title: "Procurement Managers",
              link: "category/ProcurementManager",
            },
            {
              title: "Finance Managers",
              link: "category/FinanceManager",
            },
            {
              title: "Human Resource Managers",
              link: "category/HRManager",
            },

           
          ],
        },
        {
          title: "By Problems",
          children: [
            {
              title: "Working Capital",
              link: "category/WorkingCapital",
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            },
            {
              title: "Customer Acquisition",
              link: "category/CustomerAcquisition",
              description:
                "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of ",
            },
            {
              title: "Organization Overheads",
              link: "category/OrganizationOverheads",

            },
            {
              title: "Planning and Execution",
              link: "category/PlanningAndExecution",

            },
            {
              title: "Staying Current",
              link: "category/StayingCurrent",

            },
            {
              title: "Integrity",
              link: "category/Integrity",

            },
            {
              title: "Increased Competition",
              link: "category/IncreasedCompetition",

            },
            {
              title: "Customer Loyalty",
              link: "category/CustomerLoyalty",

            },
            {
              title: "Uncertainty(Risk)",
              link: "category/Uncertainty",

            },
            {
              title: "Regulation",
              link: "category/Regulation",

            },
            {
              title: "Skill Mapping",
              link: "category/SkillMapping",

            },            
          ],
        },
        {
          title: "By Processe Area",
          children: [
            {
              title: "Procure to Pay",
              link: "category/ProcureToPay",
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            },
            {
              title: "Order to Cash",
              link: "category/OrderToCash",
              description:
                "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of ",
            },
            {
              title: "Hire to Retire",
              link: "category/HireToRetire",
            },
            {
              title: "Quote to Cash",
              link: "category/QuoteToCash",
            },
            {
              title: "Plan to Perform",
              link: "category/PlanToPerform",
            },
            {
              title: "Service-to-Satisfaction",
              link: "category/ServiceToSatisfaction",
            },
            {
              title: "Concept-to-Product",
              link: "category/ConceptToProduct",
            },
            {
              title: "Market-to-Prospect",
              link: "category/MarketToProspect",
            }          
          ],
        },
      ],
    },
    {
      title: "Integrations",
      // link: "/Solutions",
      columns: 2,

      children: [
        {
          title: "CRM",
          children: [
            {
              title: "Hub Spot",
              link: "category/WorkingCapital",
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            },
            {
              title: "Prospect CRM",
              link: "category/CustomerAcquisition",
              description:
                "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of ",
            },
            {
              title: "Salesforce",
              link: "category/OrganizationOverheads",

            },
            {
              title: "Pipedrive",
              link: "category/PlanningAndExecution",

            }          
          ],
        },
        {
          title: "Shipping",
          children: [
            {
              title: "2Ship",
              link: "category/ProcureToPay",
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            },
            {
              title: "ShipStation",
              link: "category/OrderToCash",
              description:
                "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of ",
            },
            {
              title: "Owlery",
              link: "category/HireToRetire",
            },
            {
              title: "Shippo",
              link: "category/QuoteToCash",
            }  
          ],
        },

        {
          title: "Automation",
          children: [
            {
              title: "Zapier",
              link: "category/OperationsManager",
              description:
                "ERP systems provide operations managers with a comprehensive set of tools and capabilities to address common operational challenges, improve efficiency, and drive business success in today's competitive and fast-paced business environment.",
            },
            {
              title: "Make",
              link: "category/MarketingManager",
              description:
                "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of ",
            },
            {
              title: "Syncware",
              link: "category/SalesManager",
              description : "ERP systems provide sales managers with the tools and capabilities they need to overcome common sales challenges, improve sales performance, and achieve their revenue targets in today's competitive business environment.",
            },
            {
              title: "JIKKO",
              link: "category/ProcurementManager",
            },
            {
              title: "Integrately",
              link: "category/MarketingManager",
              description:
                "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of ",
            },
            {
              title: "SyncHub",
              link: "category/SalesManager",
              description : "ERP systems provide sales managers with the tools and capabilities they need to overcome common sales challenges, improve sales performance, and achieve their revenue targets in today's competitive business environment.",
            },
            {
              title: "Pipe17",
              link: "category/ProcurementManager",
            }

          ],
        },
        {
          title: "Ecommerce",
          children: [
            {
              title: "Shopify",
              link: "category/ProcureToPay",
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            },
            {
              title: "WooCommerce",
              link: "category/OrderToCash",
              description:
                "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of ",
            },
            {
              title: "BigCommerce",
              link: "category/HireToRetire",
            },
            {
              title: "Presta Shop",
              link: "category/QuoteToCash",
            },
            {
              title: "Magento",
              link: "category/HireToRetire",
            },
            {
              title: "Amazon",
              link: "category/QuoteToCash",
            },
            {
              title: "Flipkart",
              link: "category/HireToRetire",
            },
            {
              title: "eBay",
              link: "category/QuoteToCash",
            }


        
          ],
        },
        {
          title: "Reporting",
          children: [
            {
              title: "SyncHub",
              link: "category/ProcureToPay",
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            },
            {
              title: "EasyInsight",
              link: "category/OrderToCash",
              description:
                "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of ",
            }
          ],
        },

        {
          title: "Accounting",
          children: [
            {
              title: "QuickBooks",
              link: "category/QuickBooks",
            },
            {
              title: "Xero",
              link: "category/Xero",
            }

          ],
        },

        {
          title: "Others",
          children: [
            {
              title: "WorkClout",
              link: "category/ProcureToPay",
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            },
            {
              title: "Replyco",
              link: "category/OrderToCash",
              description:
                "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of ",
            }        
          ],
        },


      ],
    },

    {
      title: "Services",
      link: "category/Services",
      layout: "Layout3",
      columns: 1,
      children: [
        {
          title: "Advisory & Assessment",
          link: "category/Assessment",
        },
        {
          title: "Implementation Services",
          link: "category/ImplementationServices",
        },
        {
          title: "ERP Customization",
          link: "category/Customization",
        },   
        {
          title: "Custom ERP Development",
          link: "category/Custom",
        },
        {
          title: "Reports & Analytics",
          link: "category/ReportsAnalytics",
        }, 
        {
          title: "Training & Change Management",
          link: "category/Education",
        },
        {
          title: "Testing Services",
          link: "category/Testing",
        },
        {
          title: "Data Migration Services",
          link: "category/DataMigration",
        },
        {
          title: "Integration Services",
          link: "category/Integration",
        },
        {
          title: "Application Support",
          link: "category/Support",
        },
      ],
    },
    {
      title: "Pricing",
      link: "category/Pricing",
      layout: "Layout4",
    },
    {
      title: "Resources",
      columns: 1,
      // link: "/Resources",
      children: [
        {
          title: "Blogs",
          link: "Resources/Blogs",
        },
        {
          title: "Case Studies",
          link: "Resources/CaseStudies",
        },
        {
          title: "Articles & Whitepapers",
          link: "Resources/WhitepapersandArticles",
        },
        {
          title: "Videos",
          link: "Resources/Videos",
        },
        {
          title: "How To's",
          link: "Resources/HowTo",
        },
        {
          title: "FAQ's",
          link: "Resources/FAQ",
        },
        {
          title: "Downloads",
          link: "Resources/Downloads",
        },
      ],
    },
    {
      title: "NewsLetters",
      link: "category/NewsLetters",
      layout: "Layout9",
    },
  ],
};
export default MainNavbar;

