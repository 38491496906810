import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  CardActions,
  Box,
  Button,
  Container,
  Divider,
} from "@material-ui/core";
import TagsIcon from "@material-ui/icons/Loyalty";
import { Link, withRouter, useParams, useLocation } from "react-router-dom";

import Axios from "axios";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import social from "../Static/social";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { useDispatch } from "react-redux";
import { openSecuredContactForm } from "../redux/action/utilActions";
import { pushtohistory } from "../redux/action/historyActions";
import SeoComponent from "../Components/Seo/SeoComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "5vh 0vw",
  },

  blogImg: {
    width: "100%",
    // backgroundColor: "red",

    height: "0vh",
  },
  blogContentContainer: {
    flex: 5,
  },
  TagsIcon: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
  tag: {
    display: "inline",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.info.light,
    borderRadius: 7,
    margin: "1vh 0.5vw",
    fontSize: 12,
  },
  tagsContainer: {
    display: "flex",
    alignItems: "center",
  },
  post: {
    // whiteSpace: "wrap",
    // overflow: "hidden",
    width: "100%",

    // textOverflow: "ellipsis",
  },
  socialContainer: {
    display: "flex",
    width: "10vw",

    alignItems: "center",
    justifyContent: "space-between",
  },
  socialIcon: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  relatedTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: "pointer",
  },
}));

const LayoutBlog = (props) => {
  const classes = useStyles();
  const [data, setData] = React.useState(null);
  let { title } = useParams();
  console.log(title);
  React.useEffect(() => {
    Axios.get(`/data/Blog.json`).then((res) => {
      // console.log(res.data);
      setData(res.data.blogs.find((item) => item.urlcode === title));
    });
  }, []);

  const handleSocialClick = (url) => {
    window.open(url, "_blank");
  };
  const handleRelatedClick = (id) => {
    props.history.push(`/blog/${id}`);
  };

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);

  return (
    <div className={classes.root}>
      <SeoComponent
        title={data?.title}
        description={data?.description}
        keywords={data?.tags}
        image={data?.img}
      />
      <Container maxWidth="md">
        {data !== null && (
          <Grid container justify="space-evenly" spacing={2}>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <img
                src={data?.img}
                alt={data?.title}
                className={classes.blogImg}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <Typography variant="h5" color="primary">
                {data.title}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <Typography variant="body2" color="textSecondary">
                Publisdhed on : {data.date}
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} xs={12} sm={12}>
              <Typography variant="body1">
                {ReactHtmlParser(data.blog)}
              </Typography>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              className={classes.tagsContainer}
            >
              <TagsIcon className={classes.TagsIcon} />
              {data.tags &&
                data.tags.map((tag, index) => {
                  return (
                    <Typography className={classes.tag} key={index}>
                      {tag}
                    </Typography>
                  );
                })}
            </Grid>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <Typography variant="h6">Share</Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <div className={classes.socialContainer}>
                <TwitterIcon
                  className={classes.socialIcon}
                  onClick={() => handleSocialClick(social.twitter)}
                />
                <FacebookIcon
                  className={classes.socialIcon}
                  onClick={() => handleSocialClick(social.facebook)}
                />
                <LinkedInIcon
                  className={classes.socialIcon}
                  onClick={() => handleSocialClick(social.linkdedin)}
                />
              </div>
            </Grid>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <Typography variant="h6">Related Blog posts</Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              {data.relatedBlogs.length > 0 &&
                data.relatedBlogs.map((blog, index) => {
                  return (
                    <div key={index}>
                      <Typography
                        variant="body1"
                        color="secondary"
                        className={classes.relatedTitle}
                        onClick={() => handleRelatedClick(blog.id)}
                      >
                        {blog.title}
                      </Typography>
                      <Divider />
                    </div>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default withRouter(LayoutBlog);
