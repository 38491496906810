import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { Grid, ListItemIcon, Paper, Typography } from "@material-ui/core";

import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {},
  gridContainer: {
    // marginTop: theme.spacing(1),
    // padding: theme.spacing(2),
    width: "60vw",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  linkTitle: {
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    paddingBottom: theme.spacing(1),
  },
  singleLink: {
    paddingTop: "1vh",
    paddingBottom: "1vh",
    paddingRight: "1vh",

    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    fontSize: "0.9rem",
  },
}));

function SimpleList(props) {
  const classes = useStyles();
  const { title, children } = props?.children;

  const ChooseMainCategory = (e, index) => {
    let text = e.target.innerText;
  };
  const ChooseSubCategory = (e, index, products) => {
    let text = e.target.innerText;
  };
  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainer} spacing={2}>
        {props?.children?.map((child, index) => {
          return (
            <Grid item lg={6}>
              <Typography className={classes.title}>{child?.title}</Typography>
              <Grid container>
                {child?.children?.map((nele, subIndex) => {
                  const { title, link } = nele;
                  return (
                    <Grid item lg={6} key={subIndex}>
                      <Link
                        to={{
                          pathname: `/${link}`,
                        }}
                        className={classes.link}
                      >
                        <Typography className={classes.singleLink}>
                          {nele.title}
                        </Typography>
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      {/* <List component="nav" aria-label="main mailbox folders">
        <ListItem>
          <ListItemText className={classes.linkTitle} primary={title} />
        </ListItem>
        <ListItem>
          <Grid container>
            {children &&
              children.length > 0 &&
              children.map((nele, subIndex) => {
                const { title, page } = nele;
                return (
                  <Grid item lg={6} key={subIndex}>
                    <Link
                      to={{
                        pathname: `/${page}`,
                      }}
                      className={classes.link}
                    >
                      <ListItemText secondary={nele.title} />
                    </Link>
                  </Grid>
                );
              })}
          </Grid>
        </ListItem> */}

      {/* {list &&
            list.length > 0 &&
            list.map((nele, subIndex) => {
              return (
                <ListItem>
                  <ListItemText secondary={nele.title} />
                </ListItem>
              );
            })} */}

      {/* {list &&
            list.length > 0 &&
            list.map((nele, subIndex) => {
              return (
                <ListItem button>
                  <ListItemText secondary={nele.title} />
                </ListItem>
              );
            })} */}
      {/* </List> */}
    </div>
  );
}

export default SimpleList;
