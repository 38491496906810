import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
} from "@material-ui/core";
import CallICon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import GlobeIcon from "@material-ui/icons/Language";
import LocationICon from "@material-ui/icons/LocationOn";
import FaceBookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import Pinterest from "@material-ui/icons/Pinterest";
import LinkedIcon from "@material-ui/icons/LinkedIn";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  Divider: {
    backgroundColor: theme.palette.pink,
    height: ".1em",
  },
  Title: {
    marginLeft: theme.spacing(3),
    textTransform: "uppercase",
    color: theme.palette.white,
  },
  blueTetx: {
    color: theme.palette.darkBlue,
    fontSize: "1rem",
    fontWeight: 600,
  },
  pinkTetx: {
    color: theme.palette.pink,
    fontSize: "1.2rem",
    fontWeight: 600,
  },
  blackTetx: {
    color: theme.palette.white,
    fontSize: "0.9rem",
    fontWeight: 400,
  },
  singleItem: {
    height: "40%",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      paddingBottom: theme.spacing(0),
    },
  },
  List: {
    marginLeft: "-1vw",
  },
  ListItem: {
    color: theme.palette.white,
    fontSize: "0.8rem",
    fontWeight: 400,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.pink,
    },
  },
  ListICon: {
    color: theme.palette.white,
    fontSize: "1.2rem",
    fontWeight: 400,
  },
  ListItemText: {
    marginLeft: "-2vw",
    color: theme.palette.white,
    fontSize: ".9rem",
    fontWeight: 400,
  },
  img: {
    width: "100%",
  },
  SocialIcon: {
    color: theme.palette.white,
    fontSize: "2.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  section3: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(4),
    },
  },
  SocialIconContainer: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(4),
    },
  },
}));
const MainFooter = (props) => {
  const { section1, section2, section3 } = props;

  const { history } = props;
  const classes = useStyles();
  const { phone, mail, website } = section1;
  const { links } = section3;

  const navigation = (path) => {
    history.push(path);
  };
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container alignItems="center">
        <Grid
          item
          container
          lg={8}
          md={12}
          sm={12}
          xs={12}
          alignItems="center"
          spacing={5}
        >
          <Grid
            item
            lg={5}
            md={12}
            sm={12}
            xs={12}
            className={classes.singleItem}
          >
            <Grid
              item
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
            >
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Divider className={classes.Divider} />
              </Grid>
              <Grid item lg={10} md={10} sm={10} xs={10}>
                <Typography className={classes.Title}>
                  {section1.title}
                </Typography>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.pinkTetx}>
                  {section1.subTitle}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.blackTetx}>
                  {section1.description}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <List disablePadding component="div" className={classes.List}>
                  <ListItem
                    onClick={() => navigation(phone.link)}
                    className={classes.ListItem}
                  >
                    <ListItemIcon>
                      <CallICon className={classes.ListICon} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.ListItemText}>
                        {phone.title}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    onClick={() => navigation(mail.link)}
                    className={classes.ListItem}
                  >
                    <ListItemIcon>
                      <MailIcon className={classes.ListICon} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.ListItemText}>
                        {mail.title}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    onClick={() => navigation(website.link)}
                    className={classes.ListItem}
                  >
                    <ListItemIcon>
                      <GlobeIcon className={classes.ListICon} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.ListItemText}>
                        {website.title}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            lg={7}
            md={12}
            sm={12}
            xs={12}
            className={classes.singleItem}
          >
            <Grid
              item
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
              spacing={1}
            >
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Divider className={classes.Divider} />
              </Grid>
              <Grid item lg={10} md={10} sm={10} xs={10}>
                <Typography className={classes.Title}>
                  {section2.title}
                </Typography>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.pinkTetx}>
                  {section2.subTitle}
                </Typography>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <img
                  src={section2.imageSrc}
                  className={classes.img}
                  alt={section2.title}
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <List disablePadding component="div" className={classes.List}>
                  {section2.offices.map((data, index) => (
                    <ListItem
                      key={index}
                      onClick={() => navigation(data.link)}
                      className={classes.ListItem}
                    >
                      <ListItemText>
                        <Typography className={classes.ListItemText}>
                          {data.title}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={4}
          md={12}
          sm={12}
          xs={12}
          className={classes.singleItem}
        >
          <Grid
            item
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            alignItems="center"
            className={classes.section3}
          >
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <Divider className={classes.Divider} />
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Typography className={classes.Title}>
                {section3.title}
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.pinkTetx}>
                {section3.subTitle}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.blackTetx}>
                {section3.description}
              </Typography>
            </Grid>
            <Grid
              item
              container
              lg={6}
              md={12}
              sm={12}
              xs={12}
              justify="space-between"
              className={classes.SocialIconContainer}
            >
              <Grid item lg={3} md="auto" sm="auto" xs="auto">
                <a
                  target="_blank"
                  href={links.facebook}
                  rel="noopener noreferrer"
                >
                  <FaceBookIcon className={classes.SocialIcon} />
                </a>
              </Grid>

              <Grid item lg={3} md="auto" sm="auto" xs="auto">
                <a
                  target="_blank"
                  href={links.twitter}
                  rel="noopener noreferrer"
                >
                  <TwitterIcon className={classes.SocialIcon} />
                </a>
              </Grid>

              <Grid item lg={3} md="auto" sm="auto" xs="auto">
                <a
                  target="_blank"
                  href={links.pinterest}
                  rel="noopener noreferrer"
                >
                  <Pinterest className={classes.SocialIcon} />
                </a>
              </Grid>

              <Grid item lg={3} md="auto" sm="auto" xs="auto">
                <a
                  target="_blank"
                  href={links.linkedIn}
                  rel="noopener noreferrer"
                >
                  <LinkedIcon className={classes.SocialIcon} />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default withRouter(MainFooter);
