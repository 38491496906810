import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  Hidden,
} from "@material-ui/core";
import ChevronUp from "@material-ui/icons/ArrowDropUp";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      justifyContent: "center",
    },
  },
  CopyRightsText: {
    color: theme.palette.white,
    fontSize: "0.9rem",
    cursor: "pointer",
  },
}));
const MainFooter = (props) => {
  const classes = useStyles();
  const { data, history } = props;
  const { copyright, links } = data;
  const navigation = (path) => {
    history.push(path);
  };

  const mobile = window.innerWidth < 1000 ? "center" : "inherit";
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container alignItems="center">
        <Grid item lg={5} sm={12} xs={12} md={12}>
          <Typography className={classes.CopyRightsText} align={mobile}>
            {copyright}
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item container direction="row" justify="space-evenly" lg={5}>
            {links.map((data, index) => (
              <Grid
                item
                lg={2}
                onClick={() => navigation(data.link)}
                key={index}
              >
                <Typography className={classes.CopyRightsText}>
                  {data.title}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <Grid item lg={2}>
            <List>
              <ListItem
                button
                onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                <ListItemIcon>
                  <ChevronUp />
                </ListItemIcon>
                <ListItemText>
                  <Typography className={classes.CopyRightsText}>
                    Back to top
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
};
export default withRouter(MainFooter);
