export const HOME = "/:stub?";
export const SUBCATEGORY = "/category/:CATEGORY/:stub?";
export const BLOGPOST = "/blog/:title/:stub?";
export const CASESTUDY = "/casestudy/:title/:stub?";
export const ARTICLES = "/articles/:title/:stub?";
export const HowTos = "/howto/:title/:stub?";

export const FAQ = "/FAQ/:title/:stub?";
export const RESOURCES = "/Resources/:subcat/:stub?";
export const ABOUTUS = "/Aboutus/:title/:stub?";
export const CONTACTUS = "/Contactus/:title/:stub?";
