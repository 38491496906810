import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import Slide from "./slide";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "99vw",
    [theme.breakpoints.down("md")]: {
      height: "40vh",
    },
  },
}));

const Slider = ({ slides }) => {
  const { data } = slides;

  const classes = useStyles();

  return (
    <Carousel
      infinite
      autoPlay={4000}
      animationSpeed={1000}
      draggable={false}
      className={classes.root}
    >
      {data.map((data) => (
        <Slide key={data.title} data={data} />
      ))}
    </Carousel>
  );
};

export default Slider;
