export default {
  mainImageSrc:
    "http://avantage.bold-themes.com/business/wp-content/uploads/sites/2/2019/04/img-callback.png",
  title: "Experience",
  subTitle1: "subtitle1",
  subTitle2: "subtitle2",

  description:
    "Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation diversity.",
  button: { title: "Button", link: "/callback" },
  cards: [
    {
      title: "Solicitory",
      description:
        "Podcasting operational change management inside of workflow",
      imageSrc: "https://fonts.gstatic.com/s/i/materialicons/alarm/v4/24px.svg",
    },
    {
      title: "Business",
      description:
        "Dynamically innovate customer service for state of the art customer.",
      imageSrc:
        "https://fonts.gstatic.com/s/i/materialicons/contactless/v4/24px.svg",
    },
    {
      title: "Resources",
      description:
        "Pursue scalable customer service through sustainable potentialities.",
      imageSrc: "https://fonts.gstatic.com/s/i/materialicons/eco/v4/24px.svg",
    },
  ],
};
