const Footer = {
  section1: {
    title: "Nuncho Software",
    subTitle: "Headquarters",
    description:
      "Organically grow the holistic world view of disruptive innovation via empowerment.",
    phone: { title: "+91 99725 91911", link: "my phone" },
    mail: { title: "Mail us", link: "ecom.erp.smb@gmail.com" },
    website: { title: "Our website", link: "nuncho.com" },
  },
  section2: {
    title: "OUR LOCATIONS",
    subTitle: "Where to find us?",
    imageSrc:
      "/world-map-12279.png",

    offices: [
      {
        title: "Bangalore",
        link: "/office1",
      },

    ],
  },
  section3: {
    title: "GET IN TOUCH",
    subTitle: "Nuncho Social link",
    description:
      "Taking seamless key performance indicators offline to maximise the long tail.",
    links: {
      facebook: "https://Facebook.com",
      twitter: "https://twitter.com",
      pinterest: "https://pinterest.com",
      linkedIn: "https://linkedin.com",
    },
  },
  bottomFooter: {
    copyright: "Copyright by Nuncho Softwares. All rights reserved.",
    links: [
      {
        title: "Home",
        link: "/",
      },
      {
        title: "About us",
        link: "/Aboutus/info",
      },
      {
        title: "Contact us",
        link: "/Contactus/info",
      },
      // {
      //   title: "Cases",
      //   link: "/",
      // },
      // {
      //   title: "Blog",
      //   link: "/",
      // },
      // {
      //   title: "Shop",
      //   link: "/shop",
      // },
    ],
  },
};
export default Footer;
