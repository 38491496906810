import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Divider } from "@material-ui/core";
import PinkButton from "../../../Components/Common/pinkButton";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.darkBlue,
    height: "30vh",
    padding: "5vh 0vw",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },

  Divider: {
    backgroundColor: theme.palette.pink,
    height: ".1em",
  },
  Title: {
    marginLeft: theme.spacing(3),
    color: theme.palette.white,
    textTransform: "uppercase",
  },
  whiteTetx: {
    color: theme.palette.white,
    fontSize: "2rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
  },
  btnContainer: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
    },
  },
}));
const QuoteContainer = (props) => {
  const classes = useStyles();
  const { data, history } = props;
  const { title, subTitle, button } = data;
  const naviagation = (path) => {
    history.push(path);
  };

  const mobile = window.innerWidth < 1000 ? "center" : "inherit";
  return (
    <div className={classes.main}>
      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          className={classes.main}
          justify={mobile}
        >
          <Grid
            item
            container
            alignItems="center"
            lg={10}
            md={12}
            sm={12}
            xs={12}
          >
            <Grid container direction="column" spacing={5}>
              <Grid
                item
                container
                lg={12}
                md={12}
                sm={12}
                xs={12}
                alignItems="center"
                spacing={1}
                justify={mobile}
              >
                <Grid item lg={1} md={2} sm={2} xs={2}>
                  <Divider className={classes.Divider} />
                </Grid>
                <Grid item lg={10} md="auto" sm="auto" xs="auto">
                  <Typography className={classes.Title}>{title}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.whiteTetx}>
                    {subTitle}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.btnContainer}>
            <PinkButton onClick={() => naviagation(button.link)}>
              {button.title}
            </PinkButton>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default withRouter(QuoteContainer);
