import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  CardActions,
  Box,
  Button,
} from "@material-ui/core";

import { Link, withRouter } from "react-router-dom";

import TagsIcon from "@material-ui/icons/Loyalty";
import Axios from "axios";
import LockIcon from "@material-ui/icons/Lock";
import UnlockIcon from "@material-ui/icons/Check";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { openSecuredContactForm } from "../redux/action/utilActions";
import { useDispatch, useSelector } from "react-redux";
import SeoComponent from "../Components/Seo/SeoComponent";
const useStyles = makeStyles((theme) => ({
  root: {},
  blogcardContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  blogImg: {
    flex: 1,
    objectFit: "contain",
    height: "30vh",
  },
  blogContentContainer: {
    flex: 5,
  },
  TagsIcon: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
  tag: {
    display: "inline",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.info.light,
    borderRadius: 7,
    margin: "1vh 0.5vw",
    fontSize: 12,
  },
  tagsContainer: {
    display: "flex",
    alignItems: "center",
  },
  post: {
    // whiteSpace: "wrap",
    // overflow: "hidden",
    width: "100%",

    // textOverflow: "ellipsis",
  },
  check: {
    fontSize: 25,
    color: "green",
    marginRight: theme.spacing(1),
  },
  lock: {
    fontSize: 25,
    color: "red",
    marginRight: theme.spacing(1),
  },
  Card: {},
  blogTitle: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
}));
const initmodalstate = {
  open: false,
  imgpath: "",
};
const LayoutBlog = (props) => {
  const classes = useStyles();
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    Axios.get(`/data/Resources.json`).then((res) => setData(res.data.blogs));
  }, []);
  const dispatch = useDispatch();
  const formSubmitted = useSelector((state) => state.util.formSubmitted);
  const handleNavigate = (title, secured) => {
    let successnavigatepath = `/blog/${title}`;
    let failpath = "/Blog";
    if (formSubmitted) {
      props.history.push(`/blog/${title}`);
    } else if (secured) {
      dispatch(openSecuredContactForm(successnavigatepath, failpath));
    } else {
      props.history.push(`/blog/${title}`);
    }
  };

  return (
    <div>
      <SeoComponent title="Blogs" description="These are blogs" />
      <Grid container justify="space-evenly" spacing={2}>
        {data.length > 0 &&
          data.map((item, index) => {
            return (
              <Grid item lg={12} key={index}>
                <Card className={classes.Card}>
                  <CardHeader subheader={`Published on : ${item.date}`} />
                  <CardContent>
                    <div className={classes.blogcardContainer}>
                      <img
                        src={item.img}
                        alt={item.title}
                        className={classes.blogImg}
                      />
                      <div className={classes.blogContentContainer}>
                        <Grid container justify="center">
                          <Grid
                            item
                            container
                            alignItems="center"
                            lg={11}
                            md={11}
                            xs={11}
                            sm={11}
                          >
                            {!!item.secured ? (
                              <LockIcon className={classes.lock} />
                            ) : (
                              <UnlockIcon className={classes.check} />
                            )}
                            <Typography
                              variant="h6"
                              color="primary"
                              className={classes.blogTitle}
                            >
                              {item.title}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={11}
                            md={11}
                            xs={11}
                            sm={11}
                            className={classes.tagsContainer}
                          >
                            <TagsIcon className={classes.TagsIcon} />
                            {item.keywords &&
                              item.keywords.map((tag, index) => {
                                return (
                                  <Typography className={classes.tag}>
                                    {tag}
                                  </Typography>
                                );
                              })}
                          </Grid>
                          <Grid item lg={11} md={11} xs={11} sm={11}>
                            <Typography
                              //   variant="body2"
                              //   color="textSecondary"
                              className={classes.post}
                            >
                              {ReactHtmlParser(item.description)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            justify="flex-end"
                            lg={11}
                            md={11}
                            sm={11}
                            xs={11}
                          >
                            <Button
                              color="secondary"
                              onClick={() =>
                                handleNavigate(item.urlcode, item.secured)
                              }
                            >
                              Read More
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default withRouter(LayoutBlog);
