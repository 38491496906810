import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Paper,
  Box,
  Container,
  CardMedia,
  useTheme,
} from "@material-ui/core";
import Chevronright from "@material-ui/icons/ChevronRight";
import { Link, withRouter } from "react-router-dom";
import Axios from "axios";
import SeoComponent from "../Components/Seo/SeoComponent";
const useStyles = makeStyles((theme) => ({
  root: {},
  usecaseTitle: {
    fontWeight: 600,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  description: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.darkBlue,
  },
  AccordionSummary: {
    backgroundColor: theme.palette.grey[100],
  },
  AccordionActions: {
    display: "flex",
    justifyContent: "flex-start",
  },
  title: {
    // marginTop: theme.spacing(2),
    fontSize: 20,
  },
}));
const Layout1 = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    Axios.get(`/data/Videos.json`).then((res) => setData(res.data.videos));
  }, []);

  const handleNaviagte = (title) => {
    props.history.push(`/blog/${title}`);
  };
  const isMobile = theme.breakpoints.down("md");

  const desktopStyle = { width: "30vw", height: "35vh" };
  const mobileStyle = { width: "80vw", height: "35vh" };

  return (
    <div>
      <SeoComponent title="Videos" description="This is videos" />
      <Container maxWidth="lg">
        <Grid container spacing={3} justify="center">
          {data &&
            data.length > 0 &&
            data.map((video, index) => {
              return (
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  container
                  justify="center"
                >
                  <Card variant="outlined">
                    <CardMedia>
                      <iframe
                        style={!isMobile ? desktopStyle : mobileStyle}
                        src={`${video.videourl}`}
                        allowfullscreen="0"
                      />
                    </CardMedia>
                    <CardContent>
                      <Typography className={classes.title}>
                        {video?.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </div>
  );
};

export default withRouter(Layout1);
