import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);
const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(5),
    },
  },
  img: {
    width: 200,
    height: 200,
    objectFit: "cover",
    borderRadius: "100%",
    [theme.breakpoints.down("md")]: {
      width: 100,
      height: 100,
    },
  },
  pinkTetx: {
    color: theme.palette.pink,
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  whiteTetx: {
    color: theme.palette.white,
    fontSize: "0.9rem",
    textAlign: "center",
    fontWeight: 400,
  },
  Name: {
    color: theme.palette.white,
    fontSize: "0.9rem",
    textAlign: "center",
    fontWeight: 400,
  },
  occupation: {
    color: theme.palette.white,
    fontSize: "1.1rem",
    textAlign: "center",
    fontWeight: 600,
  },
}));
const ReviewCard = ({ data }) => {
  const classes = useStyles();
  const { imageSrc, title, description, rating, name, organization } = data;
  let mobile = window.innerWidth < 1000 ? 1 : 0;
  return (
    <Grid
      item
      container
      alignItems="center"
      justify="center"
      lg={4}
      className={classes.container}
      spacing={mobile}
    >
      <Grid item container justify="center" lg={12}>
        <img src={imageSrc} className={classes.img} alt={title} />
      </Grid>
      <Grid item container justify="center" lg={12}>
        <Typography className={classes.pinkTetx}>{title}</Typography>
      </Grid>
      <Grid item container justify="center" lg={8}>
        <Typography className={classes.whiteTetx}>{description}</Typography>
      </Grid>
      <Grid item container justify="center" lg={8}>
        <StyledRating
          readOnly
          name="size-small"
          defaultValue={rating}
          size="small"
        />
      </Grid>
      <Grid item container justify="center" lg={8}>
        <Typography className={classes.Name}>{name}</Typography>
      </Grid>
      <Grid item container justify="center" lg={8}>
        <Typography className={classes.occupation}>{organization}</Typography>
      </Grid>
    </Grid>
  );
};
export default ReviewCard;
