import { historyTypes, siteTypes, utilTypes } from "../types";
import { setLocalContact, setLocalSubScribe } from "../utils/utils";

const initialState = {
  location: {},
  cookiedialog: false,
  consent: false,
  subscribe: false,
  contact: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    //login
    case siteTypes.GET_USER_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case siteTypes.GET_VISITOR_TOKEN_SUCCESS:
      return {
        ...state,
        id: action.payload.data.id,
        consent: action.payload.data.consent,
        logs: action.payload.data.logs,
      };
    case siteTypes.CONSENT_ACTION:
      return {
        ...state,
        consent: action.payload,
      };
    case siteTypes.SET_SUBSRCIBE_CONTACT:
      return {
        ...state,
        ...action.payload,
      };

    case utilTypes.SUBMIT_CONTACT_FORM_SUCCESS:
      setLocalContact(true);
      return {
        ...state,
        contact: true,
      };

    case historyTypes.SET_NEWSLETTER_SUCCESS:
      setLocalSubScribe(true);
      return {
        ...state,
        subscribe: true,
      };

    default:
      return state;
  }
};
export default userReducer;
