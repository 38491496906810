import { siteTypes, historyTypes } from "../types";

const initialState = {
  history: [],
  cookiedialog: false,
  email: null,
  name: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    //login
    case historyTypes.PUSH_TO_HISTORY:
      let newhisoty = state.history.push(action.payload);
      return {
        ...state,
        newhisoty,
      };

    case historyTypes.UPLOAD_HISTORY_SUCCESS:
      return {
        ...state,
        history: [],
      };
    case historyTypes.SET_NEWSLETTER:
      return {
        ...state,
        email: action.payload.email,
        name: action.payload.name,
      };

    default:
      return state;
  }
};
export default userReducer;
