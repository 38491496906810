import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Paper,
  Box,
  Container,
} from "@material-ui/core";
import Chevronright from "@material-ui/icons/ChevronRight";
import { Link, withRouter } from "react-router-dom";
import Axios from "axios";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import LockIcon from "@material-ui/icons/Lock";
import UnlockIcon from "@material-ui/icons/Check";
import { useDispatch, useSelector } from "react-redux";
import { openSecuredContactForm } from "../redux/action/utilActions";
import SeoComponent from "../Components/Seo/SeoComponent";
const useStyles = makeStyles((theme) => ({
  root: {},
  usecaseTitle: {
    fontWeight: 600,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  description: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.darkBlue,
  },
  AccordionSummary: {
    backgroundColor: theme.palette.grey[100],
  },
  AccordionActions: {
    display: "flex",
    justifyContent: "flex-start",
  },
  subject: {
    color: theme.palette.primary.main,
  },
  check: {
    fontSize: 25,
    color: "green",
    marginRight: theme.spacing(1),
  },
  lock: {
    fontSize: 25,
    color: "red",
    marginRight: theme.spacing(1),
  },
  filepath: {
    textDecoration: "none",
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  CardHeader: {
    color: theme.palette.primary.main,
    marginBottom: -theme.spacing(2),
  },
}));
const Layout1 = (props) => {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    Axios.get(`/data/Resources.json`).then((res) =>
      setData(res.data.downloads)
    );
  }, []);

  const dispatch = useDispatch();
  const formSubmitted = useSelector((state) => state.util.formSubmitted);
  const handleNavigate = (title, secured) => {
    let successnavigatepath = `/casestudy/${title}`;
    let failpath = "/CaseStudies";
    if (formSubmitted) {
      props.history.push(`/casestudy/${title}`);
    } else if (secured) {
      dispatch(openSecuredContactForm(successnavigatepath, failpath));
    } else {
      props.history.push(`/casestudy/${title}`);
    }
  };

  return (
    <div>
      <SeoComponent title="Case Studies" description="These are Case Studies" />
      <Container maxWidth="lg">
        <Grid container>
          {data.map((study, index) => {
            return (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                alignItems="center"
                key={index}
              >
                <Card className={classes.card} variant="outlined">
                  <CardHeader
                    className={classes.CardHeader}
                    title={
                      // <Typography
                      //   className={classes.subject}
                      //   variant="body1"
                      //   color="primary"
                      // >
                      study.subject
                      // </Typography>
                    }
                    action={
                      <a
                        href={study.filepath}
                        className={classes.filepath}
                        target="_blank"
                        download
                      >
                        <Button
                          endIcon={<Chevronright />}
                          disableElevation
                          color="primary"
                          variant="contained"
                          // onClick={() => handleNavigate(study.urlcode, study.secured)}
                        >
                          Download
                        </Button>
                      </a>
                    }
                  />
                  <CardContent>
                    <Typography
                      // className={classes.subject}
                      variant="body1"
                      // color="primary"
                    >
                      {study.goal}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default withRouter(Layout1);
