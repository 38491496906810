import React from "react";
import { withRouter, useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import Dividertext from "../Components/Common/DividerText";
import Layout1 from "../Layouts/Layout1";
import Axios from "axios";
import * as layouts from "../Layouts";
import { useDispatch } from "react-redux";
import { pushtohistory } from "../redux/action/historyActions";
import SeoComponent from "../Components/Seo/SeoComponent";

const useStyles = makeStyles((theme) => ({
  Container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
}));
const SubCategory = (props) => {
  const { CATEGORY } = useParams();
  // const { data } = props.location;

  const [data, setData] = React.useState({ layout: "Layout1" });
  console.log(CATEGORY);
  // const { data } = props.location;
  // console.log(data);

  const getData = () => {
    Axios.get(`/data/${CATEGORY}.json`).then((res) => setData(res.data));
  };
  React.useLayoutEffect(() => {
    window.scrollTo({
      top: 0,

      behavior: "smooth",
    });
    getData();
  }, [CATEGORY]);

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, [data.layout]);

  const classes = useStyles();
  const Input = layouts[data.layout];
  return (
    <div className={classes.root}>
      <SeoComponent
        title={data?.title}
        description={data?.description}
        keywords={data?.keywords}
        image={data?.img}
      />
      <Container maxWidth="lg" className={classes.Container}>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            {data.title && <Dividertext title={data.title} />}
          </Grid>
          <Grid item lg={12}>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              {data.title && <Input data={data} />}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default withRouter(SubCategory);
