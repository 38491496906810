import { siteTypes } from "../types";
// export const loginuser = (user) => {
//   const newUser = {
//     username: user.email,
//     password: user.password,
//     deviceInfo: {
//       deviceId: "123123",
//       deviceType: "DEVICE_TYPE_ANDROID",
//       notificationToken: "eaeqewqewq",
//     },
//   };
//   return {
//     type: userTypes.LOGIN_USER,
//     payload: {
//       request: {
//         method: "POST",
//         url: `${ROOT_URL}IDM/AUTH/login`,
//         data: newUser,
//         headers: {},
//       },
//     },
//   };
// };
// export const logInOnLoad = (token) => {
//   console.log("loginonload", token);
//   return {
//     type: userTypes.LOCAL_LOGIN_USER,
//     // payload: {
//     //   request: {
//     //     method: "PUT",
//     //     url: `${ROOT_URL}IDM/LoginUser/updateUserRole/3291`,
//     //     headers: {
//     //       Authorization: token ? `Bearer ${token}` : "",
//     //     },
//     //   },
//     //   data: { accessToken: token },
//     // },
//   };
// };
export const setUserLocation = (location) => {
  return {
    type: siteTypes.GET_USER_LOCATION,
    payload: location,
  };
};
export const getvisitortoken = (data) => {
  let preparedata = {
    countryCode: data.country_code,
    countryName: data.country_name,
    city: data.city,
    postal: data.postal,
    ip: data.IPv4,
    state: data.state,
    latitude: data.latitude,
    longitude: data.longitude,
    domain: "web",
  };
  console.log("visitor data", data);
  return {
    type: siteTypes.GET_VISITOR_TOKEN,
    payload: {
      request: {
        method: "post",
        url: "/SFA/Visitor/log",
        data: preparedata,
      },
    },
  };
};
export const consentaction = (value) => {
  return {
    type: siteTypes.CONSENT_ACTION,
    payload: value,
  };
};
export const setSubscribeContact = (data) => {
  return {
    type: siteTypes.SET_SUBSRCIBE_CONTACT,
    payload: data,
  };
};
