export default {
  mapLink:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3778.6493526706654!2d77.58058761615247!3d12.883771591960397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15949c780a79%3A0x56bacb4ed184e870!2sIncubinet%20Coworking%20Hub!5e0!3m2!1sen!2sus!4v1595496552076!5m2!1sen!2sus",
  // "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319",
  getinTouch: {
    title: "Our office",
    subTitle: "Get in Touch",
    description:
      "Come and visit our quarters or simply send us an email anytime you want. We are open to all suggestions from our clients.",
  },
  cards: [
    {
      imageSrc: "https://fonts.gstatic.com/s/i/materialicons/alarm/v4/24px.svg",
      title: "Address",
      line1:
        "4th Floor, Aditya Complex, Kothnur Main Rd, Gaurav Nagar, JP Nagar 7th Phase, Bengaluru, Karnataka 560078, India",
      // line2: "London WC1B 4EA",
    },
    {
      imageSrc: "https://fonts.gstatic.com/s/i/materialicons/alarm/v4/24px.svg",
      title: "Email us",
      line1: "ecom.erp.smb@gmail.com",
    },
    {
      imageSrc: "https://fonts.gstatic.com/s/i/materialicons/alarm/v4/24px.svg",
      title: "Call us",
      line1: "+91 999725 91911",
      // line2: "020 7996 223",
    },
  ],
};
