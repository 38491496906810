import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Divider } from "@material-ui/core";
import ExperienceCard from "./ExperienceCard";
const useStyles = makeStyles((theme) => ({
  ExperienceContainer: {
    margin: "15vh 0",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      margin: "5vh 0",
      height: "auto",
    },
  },
  img: {
    width: "80%",
  },
  Divider: {
    backgroundColor: theme.palette.pink,
    height: ".1em",
  },
  Title: {
    marginLeft: theme.spacing(3),
    textTransform: "uppercase",
  },
  blueTetx: {
    color: theme.palette.darkBlue,
    fontSize: "2.5rem",
    fontWeight: 800,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      fontSize: "1.5rem",
    },
  },
  pinkTetx: {
    color: theme.palette.pink,
    fontSize: "2.5rem",
    fontWeight: 800,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
  },
  blackTetx: {
    width: "95%",
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "2vh",
    },
  },
  cardContainer: {
    marginTop: "2vh",
    [theme.breakpoints.down("md")]: {
      margin: "2vh 0vh",
    },
  },
}));
const ExperienceContainer = ({ data }) => {
  const classes = useStyles();

  const {
    mainImageSrc,
    title,
    subTitle1,
    subTitle2,
    description1,
    description2,
    cards,
  } = data;
  return (
    <div className={classes.ExperienceContainer}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item container justify="center" lg={6} md={12} sm={12} xs={12}>
            <img src={mainImageSrc} className={classes.img} alt={title} />
          </Grid>
          <Grid item container lg={6} md={12} sm={12} xs={12}>
            <Grid item container spacing={1} md={12} sm={12} xs={12}>
              {/* colored text and title */}
              <Grid container direction="column" spacing={5}>
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  alignItems="center"
                >
                  <Grid item lg={2} md={2} sm={2} xs={2}>
                    <Divider className={classes.Divider} />
                  </Grid>
                  <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Typography className={classes.Title}>{title}</Typography>
                  </Grid>
                  <Grid item lg={12} md="auto" sm="auto" xs="auto">
                    <Typography className={classes.blueTetx}>
                      {subTitle1}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md="auto" sm="auto" xs="auto">
                    <Typography className={classes.pinkTetx}>
                      {subTitle2}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container justify="space-between">
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Typography className={classes.blackTetx}>
                    {description1}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Typography className={classes.blackTetx}>
                    {description2}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.cardContainer}
              >
                {cards.map((data) => (
                  <Grid
                    item
                    container
                    direction="column"
                    justify="center"
                    lg={4}
                    md={12}
                    sm={12}
                    xs={12}
                    key={data.title}
                  >
                    <ExperienceCard data={data} />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* //3 images card container */}
            {/* <Grid
              item
              md={12}
              sm={12}
              xs={12}
              container
              justify="center"
              style={{ backgroundColor: "red" }}
            >
              {cards.map((data) => (
                <Grid
                  item
                  container
                  direction="column"
                  justify="center"
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  key={data.title}
                >
                  <ExperienceCard data={data} />
                </Grid>
              ))}
            </Grid> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default ExperienceContainer;
