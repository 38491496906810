import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  ExpansionPanel,
  Button,
  Divider,
} from "@material-ui/core";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { Link, withRouter, useParams } from "react-router-dom";
import Axios from "axios";
const useStyles = makeStyles((theme) => ({
  root: {},
  usecaseTitle: {
    fontWeight: 600,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  description: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.darkBlue,
  },
  AccordionSummary: {
    backgroundColor: theme.palette.grey[100],
  },
  AccordionActions: {
    display: "flex",
    justifyContent: "flex-start",
  },
}));
const Layout1 = (props) => {
  const classes = useStyles();
  const { CATEGORY } = useParams();
  const [data, setData] = React.useState({ layout: "Layout1" });
  // console.log(CATEGORY);
  // const { data } = props.location;
  // console.log(data);

  const getData = () => {
    Axios.get(`/data/${CATEGORY}.json`).then((res) => setData(res.data));
  };
  React.useLayoutEffect(() => {
    window.scrollTo({
      top: 0,

      behavior: "smooth",
    });
    getData();
  }, [CATEGORY]);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h6">{data.highllight}</Typography>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography
            color="primary"
            variant="h5"
            className={classes.usecaseTitle}
          >
            Use Cases of {data.title}
          </Typography>
        </Grid>
        <List component={Grid} item container lg={12} md={12} sm={12} xs={12}>
          {data.usecases &&
            data.usecases.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  component={Grid}
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <ListItemIcon>
                    <CheckIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText>{item}</ListItemText>
                </ListItem>
              );
            })}
        </List>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography
            color="primary"
            variant="h5"
            className={classes.usecaseTitle}
          >
            Key Features
          </Typography>
        </Grid>
        <Grid item container lg={12} md={12} sm={12} xs={12}>
          {data.keyfeatures &&
            data.keyfeatures.map((item, index) => {
              return (
                <Box
                  key={index}
                  component={Grid}
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography variant="body2" className={classes.description}>
                    {item.description}
                  </Typography>
                </Box>
              );
            })}
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography
            color="primary"
            variant="h5"
            className={classes.usecaseTitle}
          >
            Related Topics
          </Typography>
        </Grid>
        <Grid item container lg={12} md={12} sm={12} xs={12}>
          {data.relatedTopics &&
            data.relatedTopics.map((item, index) => {
              return (
                <Accordion
                  component={Grid}
                  key={index}
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.AccordionSummary}
                  >
                    <Typography className={classes.heading}>
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{item.description}</Typography>
                  </AccordionDetails>
                  <Divider />
                  <AccordionActions className={classes.AccordionActions}>
                    <Button
                      color="primary"
                      onClick={() => props.history.push(`/${item.path}`)}
                    >
                      Learn More
                    </Button>
                  </AccordionActions>
                </Accordion>
              );
            })}
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Layout1);
