export default {
  title: "OUR ANNOUNCEMENTS",
  subTitle1: "Latest",
  subTitle2: "News",
  cards: [
    {
      title: "Consulting Project",
      tag1: "new",
      tag2: "Projects",
      description:
        "Nuncho Software is all about strategy, we’re here to inform which tactics need funding and which are drains on resources.",
      button: { title: "Read More", link: "/readmore" },
    },
    {
      title: "Consulting Project",
      tag1: "new",
      tag2: "Projects",
      description:
        "Nuncho Software is all about strategy, we’re here to inform which tactics need funding and which are drains on resources.",
      button: { title: "Read More", link: "/readmore" },
    },
    {
      title: "Consulting Project",
      tag1: "new",
      tag2: "Projects",
      description:
        "Nuncho Software is all about strategy, we’re here to inform which tactics need funding and which are drains on resources.",
      button: { title: "Read More", link: "/readmore" },
    },
  ],
};
