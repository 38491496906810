export default {
  logos: [
    {
      imageSrc:
        "http://avantage.bold-themes.com/business/wp-content/uploads/sites/2/2019/04/logo-bold-news.png",
    },
    {
      imageSrc:
        "http://avantage.bold-themes.com/business/wp-content/uploads/sites/2/2019/04/logo-celeste.png",
    },
    {
      imageSrc:
        "http://avantage.bold-themes.com/business/wp-content/uploads/sites/2/2019/04/logo-applauz.png",
    },
    {
      imageSrc:
        "http://avantage.bold-themes.com/business/wp-content/uploads/sites/2/2019/04/logo-hotel-berg.png",
    },
    {
      imageSrc:
        "http://avantage.bold-themes.com/business/wp-content/uploads/sites/2/2019/04/logo-hotel-california.png",
    },
    {
      imageSrc:
        "http://avantage.bold-themes.com/business/wp-content/uploads/sites/2/2019/04/logo-estato.png",
    },
  ],
};
