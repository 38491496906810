import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  Paper,
  Grid,
  TextField,
  Button,
  Modal,
  Typography,
  CircularProgress,
  withStyles,
  Card,
  CardHeader,
  CardContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { addtonewsletter } from "../../redux/action/historyActions";
const styles = (theme) => ({
  Card: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90vw",
    },
  },
  //   paper: {
  //     margin: theme.spacing.unit,
  //     padding: theme.spacing.unit * 2,
  //   },
  //   textField: {
  //     marginLeft: theme.spacing.unit,
  //     marginRight: theme.spacing.unit,
  //     width: 300,
  //   },
  //   button: {
  //     margin: theme.spacing.unit,
  //   },
  //   modalpaper: {
  //     position: "absolute",
  //     width: theme.spacing.unit * 50,
  //     backgroundColor: theme.palette.background.paper,
  //     boxShadow: theme.shadows[5],
  //     padding: theme.spacing.unit * 4,
  //   },
  //   progress: {
  //     margin: theme.spacing.unit * 1,
  //   },
});

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

class Details extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      open: false,
      open_loading: false,
      btndisabled: true,
      isClosedByUser: false,
    };
  }

  setName(e) {
    var name = e.target.value;
    this.setState({ name });
    if (this.state.name !== "") {
      this.setState({ btndisabled: false });
    } else {
      this.setState({ btndisabled: true });
    }
  }

  handleChange = (event) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });

    if (name === "email") {
      if (re.test(this.state.email)) {
        this.state.name.length > 0 && this.setState({ btndisabled: false });
      } else {
        this.setState({ btndisabled: true });
      }
    }
    if (name === "name") {
      if (value.length < 1) {
        this.setState({ btndisabled: true });
      } else {
        this.state.email !== "" && this.setState({ btndisabled: false });
      }
    }
  };

  //   setEmail(e) {
  //     var email = e.target.value;
  //     this.setState({ email });
  //   }

  submitData() {
    console.log(this.state);
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    console.log(re.test(this.state.email));

    if (!re.test(this.state.email)) {
      this.setState({ error: true });
      setTimeout(() => this.setState({ error: false }), 2000);
    } else if (re.test(this.state.email)) {
      this.props.setNewsletter(this.state.name, this.state.email);
      this.setState({ open: false });
      //   this.setState({ open_loading: true });
      //   setTimeout(() => this.setState({ open_loading: false }), 3000);
    }
  }

  // componentDidMount() {
  //   !this.state.isClosedByUser &&
  //     this.props.email === null &&
  //     !this.props.securedform &&
  //     setTimeout(() => this.setState({ open: true }), 20000);
  // }

  render() {
    const { classes } = this.props;
    return (
      this.state.open && (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          style={{
            height: "100vh",
            width: "100vw",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Card className={classes.Card}>
              <CardHeader
                title="Subscribe to our weekly Newsletter"
                action={
                  <IconButton
                    onClick={() =>
                      this.setState({ open: false, isClosedByUser: true })
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                }
              />
              <CardContent>
                <TextField
                  variant="filled"
                  id="with-placeholder"
                  label="Name"
                  name="name"
                  focused={true}
                  fullWidth
                  placeholder="name"
                  className={this.props.classes.textField}
                  onChange={this.handleChange.bind(this)}
                />
              </CardContent>
              <CardContent>
                <TextField
                  variant="filled"
                  fullWidth
                  id="with-placeholder"
                  label="Email"
                  name="email"
                  placeholder="e-mail"
                  type="email"
                  className={this.props.classes.textField}
                  helperText={this.state.error && "Enter Valid Email"}
                  error={this.state.error}
                  onChange={this.handleChange.bind(this)}
                />
              </CardContent>
              <CardContent>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={this.props.classes.button}
                  onClick={this.submitData.bind(this)}
                  disabled={this.state.btndisabled}
                >
                  Subscribe
                </Button>
              </CardContent>
            </Card>

            <Modal open={this.state.open_loading}>
              <div
                style={getModalStyle()}
                className={this.props.classes.modalpaper}
              >
                <CircularProgress
                  className={this.props.classes.progress}
                  size={40}
                />
                <h2> Please wait ...</h2>
              </div>
            </Modal>
          </div>
        </Modal>
      )
    );
  }
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  email: state.history.email,
  securedform: state.util.securedContactFormOpen,
});
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setNewsletter: (name, email) => dispatch(addtonewsletter(name, email)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Details)
);
