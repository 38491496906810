import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useDispatch } from "react-redux";
import { consentaction } from "../../redux/action/useractions";
const useStyles = makeStyles((theme) => ({
  root: {},
}));
const CookieDialog = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="CookieConsent"
        style={{ background: "#2B373B", height: "10vh" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        enableDeclineButton
        onAccept={() => {
          dispatch(consentaction(true));
        }}
        onDecline={() => dispatch(consentaction(false))}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    </div>
  );
};

export default CookieDialog;
