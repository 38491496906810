import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  ExpansionPanel,
  Button,
  Divider,
  Modal,
  Card,
  CardHeader,
  IconButton,
  CardContent,
} from "@material-ui/core";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { Link, withRouter } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  root: {},
  usecaseTitle: {
    fontWeight: 600,
  },
  featureTitle: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  listItem: {
    margin: theme.spacing(1),
  },
  img: {
    objectFit: "contain",
    width: "100%",
    height: "20vh",
  },
  modalRoot: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardRoot: {
    height: "80%",
    width: "90%",
    overflow: "auto",
  },
  modalImg: {
    width: "100%",
    objectFit: "contain",
  },
  solContainer: {
    marginTop: theme.spacing(2),
    // borderStyle: "solid",
    // borderWidth: 2,
    // borderRadius: 10,
    // marginBottom: theme.spacing(2),
    // borderColor: theme.palette.grey[500],
  },
}));
const initmodalstate = {
  open: false,
  imgpath: "",
};
const Layout1 = (props) => {
  const classes = useStyles();
  const { data } = props;
  const [modalOpen, setModalOpen] = React.useState(initmodalstate);
  return (
    <div>
      <Modal
        open={modalOpen.open}
        className={classes.modalRoot}
        onClose={() => setModalOpen(initmodalstate)}
      >
        <Card className={classes.cardRoot}>
          <CardHeader
            action={
              <IconButton onClick={() => setModalOpen(initmodalstate)}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <img className={classes.modalImg} src={modalOpen.imgpath} />
          </CardContent>
        </Card>
      </Modal>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ul>
            {data.highlights.map((item, index) => {
              return (
                <li className={classes.listItem} key={index}>
                  <span className={classes.featureTitle}>
                    {item.feature} :{" "}
                  </span>
                  <span className={classes.featureDesription}>
                    {item.description}
                  </span>
                </li>
              );
            })}
          </ul>
        </Grid>

        {data.solutions.map((sol, index) => {
          return (
            <Grid
              item
              container
              component={Card}
              variant="outlined"
              alignItems="center"
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.solContainer}
            >
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <img
                  src={sol.imagepath}
                  className={classes.img}
                  alt={sol.imagepath}
                  onClick={() =>
                    setModalOpen({ open: true, imgpath: sol.imagepath })
                  }
                />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={12}>
                {sol.highlights.length > 0 &&
                  sol.highlights.map((item, index) => {
                    return (
                      <li className={classes.listItem} key={index}>
                        <span className={classes.featureTitle}>
                          {item.feature} :{" "}
                        </span>
                        <span className={classes.featureDesription}>
                          {item.description}
                        </span>
                      </li>
                    );
                  })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default withRouter(Layout1);
