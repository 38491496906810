import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Typography from "@material-ui/core/Typography";

import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},

  cover: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  content: {},
  blueTetx: {
    color: theme.palette.darkBlue,
    fontSize: "1.1rem",
  },
  blackTetx: {
    marginTop: "1vh",
    color: theme.palette.text.secondary,
    fontSize: ".9rem",
    fontWeight: 400,
  },
}));

export default function HelpCard({ data }) {
  const classes = useStyles();
  const { title, imageSrc, line1, line2 } = data;

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent className={classes.content}>
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <img src={imageSrc} className={classes.cover} alt={title} />
          </Grid>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Typography
              component="h5"
              variant="h5"
              className={classes.blueTetx}
            >
              {title}
            </Typography>
            <Typography className={classes.blackTetx}>{line1}</Typography>
            <Typography className={classes.blackTetx}>{line2}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
