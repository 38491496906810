import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Divider, Typography } from "@material-ui/core";
import ReviewCard from "./ReviewCard";
const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: "3vh",
    paddingBottom: "6vh",
    backgroundColor: theme.palette.darkBlue,
    [theme.breakpoints.down("md")]: {
      padding: "5vh 0vw",
    },
  },
  container: {
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  Divider: {
    backgroundColor: theme.palette.pink,
    height: ".1em",
  },
  Title: {
    marginLeft: theme.spacing(3),
    textTransform: "uppercase",
    color: theme.palette.white,
  },
  whiteTetx: {
    color: theme.palette.white,
    fontSize: "2rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  pinkTetx: {
    color: theme.palette.pink,
    fontSize: "2rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  blackTetx: {
    color: theme.palette.black,
    fontSize: "1.2rem",
    fontWeight: 400,
  },
  headers: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
  cardContainer: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
}));
const ComponentName = ({ data }) => {
  const classes = useStyles();
  const { title, subTitle1, subTitle2, reviewers } = data;

  let center = window.innerWidth < 1000 ? "center" : "inherit";

  return (
    <div className={classes.main}>
      <Container maxWidth="lg">
        <Grid container className={classes.container}>
          <Grid item container direction="column" justify="center">
            <Grid
              item
              container
              lg={8}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
            >
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Divider className={classes.Divider} />
              </Grid>
              <Grid item lg={10} md={10} sm={10} xs={10}>
                <Typography className={classes.Title}>{title}</Typography>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.headers}
              >
                <Typography className={classes.whiteTetx} align={center}>
                  {subTitle1}
                </Typography>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                align={center}
                className={classes.headers}
              >
                <Typography className={classes.pinkTetx}>
                  {subTitle2}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            container
            direction="row"
            justify="center"
            className={classes.cardContainer}
          >
            {reviewers.map((data, index) => (
              <ReviewCard data={data} key={index} />
            ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default ComponentName;
