import { combineReducers } from "redux";

import userReducer from "./userReducer";
import historyReducer from "./historyReducer";
import utilReducer from "./utilReducer";

const initialState = {
  values: {},
};

export default combineReducers({
  user: userReducer,
  history: historyReducer,
  util: utilReducer,
});
