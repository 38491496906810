import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Paper,
  Box,
  Container,
  AccordionActions,
} from "@material-ui/core";
import Chevronright from "@material-ui/icons/ChevronRight";
import { Link, withRouter } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const useStyles = makeStyles((theme) => ({
  root: {},
  usecaseTitle: {
    fontWeight: 600,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  description: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.darkBlue,
  },
  AccordionSummary: {
    backgroundColor: theme.palette.grey[100],
  },
  AccordionActions: {
    display: "flex",
    justifyContent: "flex-start",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
const Layout1 = (props) => {
  const classes = useStyles();
  const { data } = props;
  const handlecasestudy = (title) => {
    props.history.push(`/casestudy/${title}`);
  };
  return (
    <div>
      <Container maxWidth="lg">
        {data.questions.length > 0 &&
          data.questions.map((item, index) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {item.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{item.answer}</Typography>
                </AccordionDetails>
                <AccordionActions className={classes.AccordionActions}>
                  <Button>Learn More</Button>
                </AccordionActions>
              </Accordion>
            );
          })}
      </Container>
    </div>
  );
};

export default withRouter(Layout1);
