import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Slide,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import logo from "../../Static/logo.png";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { useHistory } from "react-router-dom";
import { ChevronLeft } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      backgroundColor: "white",
    },
  },
  menuIcon: {
    color: theme.palette.secondary.main,
  },
  closeIcon: {
    fontSize: "2rem",
  },
  container: {
    width: "100vw",
    minHeight: "100vh",
  },
  HeaderContainer: {
    backgroundColor: theme.palette.action.hover,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    height: "10vh",
  },
  submenuTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: "1.2rem",
  },
  //   scrollableList: {
  //     height: "50vh",

  //   },
}));

const MobileNav = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({
    drawerOpen: false,
    submenu: false,
    subMenuData: undefined,
    lastData: undefined,
    depth: 0,
  });

  const { children } = props;

  //close drawer
  const handleCloseDrawer = () => {
    setState({
      drawerOpen: false,
      submenu: false,
      subMenuData: undefined,
      lastData: undefined,
      depth: 0,
    });
  };

  //close submenu

  const handleCloseSubMenu = () => {
    setState({
      drawerOpen: true,
      submenu: false,
      subMenuData: undefined,
      lastData: undefined,
      depth: 0,
    });
  };

  //open drawer
  const handleOpenDrawer = () => {
    setState({
      ...state,
      drawerOpen: true,
    });
  };

  //navigation
  const handleNavigate = (data) => {
    if (data?.children) {
      return setState({
        ...state,
        submenu: true,
        subMenuData: data,
        depth: state.depth + 1,
      });
    }
    history.push(`/${data.link}`);
    handleCloseDrawer();
  };

  //subnavigation

  const handleSubNavigate = (data, lastdata) => {
    let lastDepth = state.depth;

    if (data?.children) {
      return setState({
        ...state,
        submenu: true,
        subMenuData: data,
        lastData: lastdata,
        depth: lastDepth + 1,
      });
    }
    history.push(`/${data.link}`);
    handleCloseDrawer();
  };

  //back button
  const handleSubMenuback = () => {
    if (state.depth === 1) {
      return handleCloseSubMenu();
    }
    const lastDepth = state.depth;
    setState({
      ...state,
      subMenuData: state.lastData,
      lastData: undefined,
      depth: lastDepth - 1,
    });
  };

  const firstSubMenu = (
    <Slide direction="right" in={state.submenu} mountOnEnter unmountOnExit>
      <List className={classes.scrollableList}>
        <ListItem button onClick={() => handleSubMenuback()}>
          <ListItemIcon>
            <ChevronLeft />
          </ListItemIcon>
          <ListItemText primary={"Back"} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <Typography className={classes.submenuTitle}>
                {state.subMenuData?.title}
              </Typography>
            }
          />
        </ListItem>
        <Divider />

        {state.subMenuData?.children?.map((child, index) => {
          return (
            <React.Fragment key={index}>
              <ListItem
                key={index}
                button
                onClick={() => handleSubNavigate(child, state.subMenuData)}
              >
                <ListItemText primary={child.title} />
                {child?.children && (
                  <ListItemSecondaryAction>
                    <ChevronRight />
                  </ListItemSecondaryAction>
                )}
              </ListItem>

              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    </Slide>
  );
  return (
    <div className={classes.root}>
      <IconButton onClick={() => handleOpenDrawer()}>
        <MenuIcon className={classes.menuIcon} />
      </IconButton>
      <Drawer
        anchor="left"
        open={state.drawerOpen}
        onClose={() => handleCloseDrawer()}
      >
        <Card className={classes.container}>
          <CardContent className={classes.HeaderContainer}>
            <div className={classes.cardHeaderTitle}>
              <img src={logo} className={classes.logo} />
            </div>
            <IconButton onClick={() => handleCloseDrawer()}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </CardContent>

          <Divider />

          {/* first submenu */}
          {firstSubMenu}

          {!state.submenu && (
            <List>
              {children?.map((child, index) => {
                return (
                  <React.Fragment key={index}>
                    <ListItem button onClick={() => handleNavigate(child)}>
                      <ListItemText primary={child.title} />
                      {child?.children && (
                        <ListItemSecondaryAction>
                          <ChevronRight />
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>

                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          )}
        </Card>
      </Drawer>
    </div>
  );
};
export default MobileNav;
