import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Popover from "material-ui-popup-state/HoverPopover";
import Button from "@material-ui/core/Button";
import { bindHover, bindMenu } from "material-ui-popup-state/hooks";
import PopupState, { bindPopper } from "material-ui-popup-state";

import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  Popper,
  Fade,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import Menu from "material-ui-popup-state/HoverMenu";

import * as layouts from "./MenuLayouts/index";
const useStyles = makeStyles((theme) => ({
  Paper: {
    border: "1px solid rgba(0,0,0,0.3)",
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    maxWidth: "60vw",
  },
}));

const HoverPopoverPopupState = (props) => {
  const [arrowRef, setArrowRef] = React.useState(null);
  const classes = useStyles();
  const { history } = props;
  const { title, link, children, columns } = props.children;
  // console.log("props", props);
  // const navigate = (path) => {
  //   history.push(path);
  // };

  //show icon
  let ShowIcon = children?.length > 0 ? <DownIcon /> : null;
  // let ShowIcon = <DownIcon />;

  const navigatetopath = (path) => {
    if (path === "/") {
      history.push("/");
    } else {
      path && history.push(`/${path}`);
    }
  };
  const columnName = columns ? (columns === 1 ? "One" : "Two") : "One";
  const Layout = layouts[columnName];

  return (
    <div>
      <span className={classes.arrow} ref={setArrowRef} />
      <PopupState variant="popper" popupId="demo-popup-popper">
        {(popupState) => (
          <div>
            <Button
              {...bindHover(popupState)}
              endIcon={ShowIcon}
              onClick={() => navigatetopath(link)}
              // onClick={e => chooseType(e, index)}
              // onMouseOver={e => chooseType(e, index)}
            >
              {title}
              {/* {item.type} */}
            </Button>
            {/* {children?.map((child, index) => { */}
            {children && (
              <Popper
                className={classes.Popper}
                {...bindPopper(popupState)}
                transition
              >
                <Paper
                  className={classes.Paper}
                  onMouseLeave={(e) => popupState.onMouseLeave(e)}
                >
                  <Layout children={children} />
                </Paper>
              </Popper>
            )}
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default withRouter(HoverPopoverPopupState);

//menu but having jumping issue

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";

// import Menu from "material-ui-popup-state/HoverMenu";
// import MenuItem from "@material-ui/core/MenuItem";
// import Button from "@material-ui/core/Button";
// import { withRouter, Link } from "react-router-dom";
// import {
//   usePopupState,
//   bindHover,
//   bindMenu,
// } from "material-ui-popup-state/hooks";
// import DownIcon from "@material-ui/icons/KeyboardArrowDown";
// import SingleList from "./SingleList";
// import { Grid, Paper, List, ListItem, ListItemText } from "@material-ui/core";
// const useStyles = makeStyles((theme) => ({
//   btn: {
//     color: theme.palette.black,
//     fontSize: "1rem",
//     fontWeight: 400,
//     textTransform: "none",
//     backgroundColor: "none",
//     "&:hover": {
//       color: theme.palette.pink,
//       backgroundColor: "none",
//     },
//   },
//   link: {
//     padding: theme.spacing(3),
//     color: theme.palette.pink,
//     fontSize: "1rem",
//     textTransform: "none",
//     backgroundColor: "none",
//     "&:hover": {
//       backgroundColor: theme.palette.white,
//     },
//   },
//   Link: {
//     textDecoration: "none",
//   },
//   menu: {
//     paddingTop: theme.spacing(1),
//     maxWidth: "90vw",
//   },
// }));
// const SubMenu = (props) => {
//   const classes = useStyles();
//   const { data, history } = props;
//   const { title, link, subMenu, singleMenu } = data;
//   const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
// const navigate = (path) => {
//   path && history.push(path);
// };
//   let ShowIcon =
//     subMenu.length > 0 || (singleMenu && singleMenu.length > 0) ? (
//       <DownIcon />
//     ) : null;

//   return (
//     <div>
//       <Button
//         {...bindHover(popupState)}
//         onClick={() => navigate(link)}
//         endIcon={ShowIcon}
//         className={classes.btn}
//       >
//         {title}
//       </Button>
//       {subMenu && subMenu.length > 0 ? (
//         <Menu
//           {...bindMenu(popupState)}
//           className={classes.menu}
//           getContentAnchorEl={null}
//           anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
//           transformOrigin={{ vertical: "top", horizontal: "left" }}
//         >
//           <Paper elevation={0}>
//             <Grid container spacing={1} className={classes.link}>
//               {subMenu &&
//                 subMenu.map((data, index) => (
//                   <Grid item lg={6} key={index}>
//                     <SingleList data={data} />
//                   </Grid>
//                 ))}
//             </Grid>
//           </Paper>
//         </Menu>
//       ) : singleMenu && singleMenu.length > 0 ? (
//         <Menu
//           {...bindMenu(popupState)}
//           className={classes.menu}
//           getContentAnchorEl={null}
//           anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
//           transformOrigin={{ vertical: "top", horizontal: "left" }}
//         >
//           <Paper elevation={0}>
//             <List>
//               {singleMenu.map((data, index) => (
//                 <ListItem
//                   component={Link}
//                   to={{
//                     pathname: `/${data.page}`,
//                     state: data.title,
//                   }}
//                   className={classes.Link}
//                 >
//                   <ListItemText secondary={data.title} />
//                 </ListItem>
//               ))}
//             </List>
//           </Paper>
//         </Menu>
//       ) : null}
//     </div>
//   );
// };

// export default withRouter(SubMenu);

//with popper

// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";
// import Popover from "material-ui-popup-state/HoverPopover";
// import Button from "@material-ui/core/Button";
// import { bindHover } from "material-ui-popup-state/hooks";
// import PopupState, { bindPopper } from "material-ui-popup-state";

// import DownIcon from "@material-ui/icons/KeyboardArrowDown";
// import { Popper, Fade, Paper, Grid } from "@material-ui/core";
// import SingleList from "./SingleList";

// const useStyles = makeStyles((theme) => ({
//   Paper: {
//     marginTop: theme.spacing(1),
//     padding: theme.spacing(2),
//     maxWidth: "50vw",
//   },
// }));

// const HoverPopoverPopupState = (props) => {
//   const chooseType = (text, index) => {
//     let categoryName = text.target.textContent;
//     console.log(text.target.textContent, index, "choosen type");
//   };
//   const classes = useStyles();
//   const { data, history } = props;
//   const { title, link, subMenu } = data;

//   const navigate = (path) => {
//     history.push(path);
//   };

//   //show icon
//   let ShowIcon = subMenu.length > 0 ? <DownIcon /> : null;

//   return (
//     <div>
//       <PopupState variant="popper" popupId="demo-popup-popper">
//         {(popupState) => (
//           <div>
//             <Button
//               {...bindHover(popupState)}
//               endIcon={ShowIcon}
//               // onClick={e => chooseType(e, index)}
//               // onMouseOver={e => chooseType(e, index)}
//             >
//               {title}
//               {/* {item.type} */}
//             </Button>
//             <Popper
//               className={classes.Popper}
//               {...bindPopper(popupState)}
//               transition
//               modifiers={
//                 {
//                   // flip: {
//                   //   enabled: true,
//                   // },
//                   // preventOverflow: {
//                   //   enabled: true,
//                   //   boundariesElement: "scrollParent",
//                   // },
//                 }
//               }
//             >
//               {({ TransitionProps }) => (
//                 <Fade {...TransitionProps} timeout={350}>
//                   <Paper
//                     className={classes.Paper}
//                     onMouseLeave={(e) => popupState.onMouseLeave(e)}
//                   >
//                     <Grid container spacing={1}>
//                       {subMenu.map((data) => (
//                         <Grid item lg={6}>
//                           <SingleList data={data} />
//                         </Grid>
//                       ))}
//                     </Grid>
//                   </Paper>
//                 </Fade>
//               )}
//             </Popper>
//           </div>
//         )}
//       </PopupState>
//     </div>
//   );
// };

// export default HoverPopoverPopupState;

//tried creting arrow but not working

// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";
// import Popover from "material-ui-popup-state/HoverPopover";
// import Button from "@material-ui/core/Button";
// import {
//   usePopupState,
//   bindHover,
//   bindPopover
// } from "material-ui-popup-state/hooks";
// import DownIcon from "@material-ui/icons/KeyboardArrowDown";
// import { Popper, Fade, Paper } from "@material-ui/core";
// import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
// import "./popoverstyle.css";

// const useStyles = makeStyles(theme => ({
//   Popper: {
//     zIndex: 100000
//   },
//   Paper: {
//     marginTop: theme.spacing(10),
//     padding: theme.spacing(3)
//   },
//   popper: {
//     zIndex: 1,
//     '&[x-placement*="bottom"] $arrow': {
//       top: 0,
//       left: 0,
//       marginTop: "-0.9em",
//       width: "3em",
//       height: "1em",
//       "&::before": {
//         borderWidth: "0 1em 1em 1em",
//         borderColor: `transparent transparent ${theme.palette.common.white} transparent`
//       }
//     },
//     '&[x-placement*="top"] $arrow': {
//       bottom: 0,
//       left: 0,
//       marginBottom: "-0.9em",
//       width: "3em",
//       height: "1em",
//       "&::before": {
//         borderWidth: "1em 1em 0 1em",
//         borderColor: `${theme.palette.common.white} transparent transparent transparent`
//       }
//     },
//     '&[x-placement*="right"] $arrow': {
//       left: 0,
//       marginLeft: "-0.9em",
//       height: "3em",
//       width: "1em",
//       "&::before": {
//         borderWidth: "1em 1em 1em 0",
//         borderColor: `transparent ${theme.palette.common.white} transparent transparent`
//       }
//     },
//     '&[x-placement*="left"] $arrow': {
//       right: 0,
//       marginRight: "-0.9em",
//       height: "3em",
//       width: "1em",
//       "&::before": {
//         borderWidth: "1em 0 1em 1em",
//         borderColor: `transparent transparent transparent ${theme.palette.common.white}`
//       }
//     }
//   },
//   arrow: {
//     position: "absolute",
//     fontSize: 7,
//     width: "3em",
//     height: "3em",
//     "&::before": {
//       content: '""',
//       margin: "auto",
//       display: "block",
//       width: 0,
//       height: 0,
//       borderStyle: "solid"
//     }
//   }
// }));

// const HoverPopoverPopupState = ({ classes }) => {
//   const popupState = usePopupState({
//     variant: "popover",
//     popupId: "demoPopover"
//   });
//   const [arrowRef, setArrowRef] = useState(null);
// const handleArrowRef = node => {
//   console.log(node, "arrow ref triggred");
//   setArrowRef(node);
// };

//   const dashes = useStyles();
//   return (
//     <div>
//       <PopupState variant="popper" popupId="demo-popup-popper">
//         {popupState => (
//           <div>
//             <Button
//               ref={arrowRef}
//               {...bindHover(popupState)}
//               endIcon={<DownIcon />}
//             >
//               item
//             </Button>
//             <Popper
//               className={dashes.Popper}
//               {...bindPopper(popupState)}
//               transition
//               disablePortal={false}
//               modifiers={{
//                 flip: {
//                   enabled: true
//                 },
//                 preventOverflow: {
//                   enabled: true,
//                   boundariesElement: "scrollParent"
//                 },

//                 arrow: {
//                   enabled: true,
//                   ref: arrowRef
//                 }
//               }}
//             >
// {({ TransitionProps }) => (
//   <Fade {...TransitionProps} timeout={350}>
//     <Paper className={dashes.Paper}>
//       {true && (
//         <span className={dashes.arrow} ref={handleArrowRef} />
//       )}
//       <Typography>The content of the Popper.</Typography>
//     </Paper>
//   </Fade>
// )}
//             </Popper>
//           </div>
//         )}
//       </PopupState>
//     </div>
//   );
// };

// export default HoverPopoverPopupState;

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";

// import Menu from "material-ui-popup-state/HoverMenu";
// import MenuItem from "@material-ui/core/MenuItem";
// import Button from "@material-ui/core/Button";
// import SingleList from "./SingleList";
// import { withRouter } from "react-router-dom";
// import {
//   usePopupState,
//   bindHover,
//   bindMenu,
// } from "material-ui-popup-state/hooks";
// const useStyles = makeStyles((theme) => ({
//   btn: {
//     color: theme.palette.black,
//     fontSize: "1rem",
//     fontWeight: 400,
//     textTransform: "none",
//     backgroundColor: "none",
//     "&:hover": {
//       color: theme.palette.pink,
//       backgroundColor: "none",
//     },
//   },
//   link: {
//     color: theme.palette.black,
//     fontSize: "1rem",
//     textTransform: "none",
//     backgroundColor: "none",
//     "&:hover": {
//       color: theme.palette.pink,
//     },
//   },
// }));
// const SubMenu = (props) => {
//   const classes = useStyles();
// const { data, history } = props;
// const { title, link, subMenu } = data;
//   const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
// const navigate = (path) => {
//   history.push(path);
// };
//   return (
//     <div>
//       <Button
//         {...bindHover(popupState)}
//         onClick={() => navigate(link)}
//         className={classes.btn}
//       >
//         {title}
//       </Button>
//       {subMenu.length > 0 && (
//         <Menu
//           {...bindMenu(popupState)}
//           anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
//           transformOrigin={{ vertical: "top", horizontal: "left" }}
//         >
//           {subMenu.map(({ title, link }) => (
//             <SingleList />
//           ))}

//           {/* {subMenu.map(({ title, link }) => (
//             <MenuItem
//               key={title}
//               onClick={() => navigate(link)}
//               className={classes.link}
//             >
//               {title}
//             </MenuItem>
//           ))} */}
//         </Menu>
//       )}
//     </div>
//   );
// };

// export default withRouter(SubMenu);
