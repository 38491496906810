import { siteTypes, utilTypes } from "../types";

const initialState = {
  securedContactFormOpen: false,
  successpath: null,
  failpath: null,
  requestSuccess: null,
  formSubmitted: false,
  formMessage: undefined,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    //login
    case utilTypes.OPEN_SECURED_CONTACT_FORM:
      return {
        ...state,
        securedContactFormOpen: true,
        successpath: action.payload.successpath,
        failpath: action.payload.failpath,
      };
    case utilTypes.CLOSE_SECURED_CONTACT_FORM:
      return {
        ...state,
        securedContactFormOpen: false,
        successpath: null,
        failpath: null,
        requestSuccess: null,
      };
    case utilTypes.SUBMIT_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        requestSuccess: true,
        formSubmitted: true,
        formMessage: {
          type: "success",
          message: "Details Submitted successfully",
        },
      };
    case utilTypes.SUBMIT_CONTACT_FORM_FAIL:
      return {
        ...state,
        requestSuccess: false,
        formMessage: {
          type: "error",
          message: "An error occured while submitting details.",
        },
      };

    case siteTypes.SET_SUBSRCIBE_CONTACT:
      return {
        ...state,
        formSubmitted: action.payload.contact,
      };
    default:
      return state;
  }
};
export default userReducer;
