export const siteTypes = {
  GET_USER_LOCATION: "GET_USER_LOCATION",
  GET_USER_LOCATION_SUCCESS: "GET_USER_LOCATION_SUCCESS",
  GET_USER_LOCATION_FAIL: "GET_USER_LOCATION_FAIL",

  PUSH_USER_HISTORY: "PUSH_USER_HISTORY",
  PUSH_USER_HISTORY_SUCCESS: "PUSH_USER_HISTORY_SUCCESS",
  PUSH_USER_HISTORY_FAIL: "PUSH_USER_HISTORY_FAIL",

  GET_VISITOR_TOKEN: "GET_VISITOR_TOKEN",
  GET_VISITOR_TOKEN_SUCCESS: "GET_VISITOR_TOKEN_SUCCESS",
  GET_VISITOR_TOKEN_FAIL: "GET_VISITOR_TOKEN_FAIL",

  CONSENT_ACTION: "CONSENT_ACTION",

  SET_SUBSRCIBE_CONTACT: "SET_SUBSRCIBE_CONTACT",
};
export const historyTypes = {
  PUSH_TO_HISTORY: " PUSH_TO_HISTORY",
  UPLOAD_HISTORY: "UPLOAD_HISTORY",
  UPLOAD_HISTORY_SUCCESS: "UPLOAD_HISTORY_SUCCESS",
  UPLOAD_HISTORY_FAIL: "UPLOAD_HISTORY_FAIL",
  SET_NEWSLETTER: "SET_NEWSLETTER",
  SET_NEWSLETTER_SUCCESS: "SET_NEWSLETTER_SUCCESS",
  SET_NEWSLETTER_FAIL: "SET_NEWSLETTER_FAIL",
};
export const utilTypes = {
  OPEN_SECURED_CONTACT_FORM: "OPEN_SECURED_CONTACT_FORM",
  CLOSE_SECURED_CONTACT_FORM: "CLOSE_SECURED_CONTACT_FORM",

  SUBMIT_CONTACT_FORM: "SUBMIT_CONTACT_FORM",
  SUBMIT_CONTACT_FORM_SUCCESS: "SUBMIT_CONTACT_FORM_SUCCESS",
  SUBMIT_CONTACT_FORM_FAIL: "SUBMIT_CONTACT_FORM_FAIL",
};
