import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Divider,
  Typography,
  Hidden,
} from "@material-ui/core";
import PinkButton from "../../../Components/Common/pinkButton";
import BlueButton from "../../../Components/Common/blueButton";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",

    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "40vh",
    },
  },
  Container: {
    height: "100vh",

    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "40vh",
    },
  },
  gridContainer: {
    minHeight: "30vh",
    [theme.breakpoints.down("md")]: {
      minHeight: "30vh",
    },
  },
  Divider: {
    backgroundColor: theme.palette.pink,
    height: ".1em",
  },
  Title: {
    marginLeft: theme.spacing(3),
    textTransform: "uppercase",
  },
  blueTetx: {
    color: theme.palette.darkBlue,
    fontSize: "4rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  pinkTetx: {
    color: theme.palette.pink,
    fontSize: "4rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  blackTetx: {
    color: theme.palette.black,
    fontSize: "1.2rem",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
}));

const Slide = (props) => {
  const { data, history } = props;
  const classes = useStyles();
  const {
    title,
    subtitleLine1,
    subtitleLine2,
    description,
    button1,
    button2,
  } = data;
  const navigate = (path) => {
    history.push(path);
  };
  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: `url(${data.imageSrc})`,
        backgroundSize: "cover",
      }}
    >
      <Container maxWidth="lg">
        <div className={classes.Container}>
          <Grid
            container
            className={classes.gridContainer}
            direction="column"
            spacing={5}
          >
            <Grid
              item
              container
              lg={8}
              sm={8}
              md={8}
              xs={12}
              alignItems="center"
            >
              <Grid item lg={2} sm={2} md={2} xs={2}>
                <Divider className={classes.Divider} />
              </Grid>
              <Grid item lg={10} sm={10} md={10} xs={10}>
                <Typography className={classes.Title}>{title}</Typography>
              </Grid>
              <Grid item lg={12} sm={12} md={12} xs={12}>
                <Typography className={classes.blueTetx}>
                  {subtitleLine1}
                </Typography>
              </Grid>
              <Grid item lg={12} sm={12} md={12} xs={12}>
                <Typography className={classes.pinkTetx}>
                  {subtitleLine2}
                </Typography>
              </Grid>
              <Grid item lg={8} sm={8} md={8} xs={12}>
                <Typography className={classes.blackTetx}>
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <Hidden mdDown>
              <Grid
                item
                container
                lg={6}
                sm={6}
                md={6}
                direction="row"
                justify="space-between"
              >
                <Grid item lg={5} sm={5} md={5}>
                  <PinkButton fullWidth onClick={() => navigate(button1.link)}>
                    {button1.title}
                  </PinkButton>
                </Grid>
                <Grid item lg={5} sm={5} md={5}>
                  <BlueButton fullWidth onClick={() => navigate(button2.link)}>
                    {button2.title}
                  </BlueButton>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(Slide);
