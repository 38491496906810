import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Paper,
  Box,
  Container,
  AccordionActions,
} from "@material-ui/core";
import Chevronright from "@material-ui/icons/ChevronRight";
import { Link, withRouter, useParams, useLocation } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Axios from "axios";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { useDispatch } from "react-redux";
import { openSecuredContactForm } from "../redux/action/utilActions";
import { pushtohistory } from "../redux/action/historyActions";
import SEO from "@americanexpress/react-seo";
import SeoComponent from "../Components/Seo/SeoComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "5vh 0vw",
  },

  AccordionSummary: {
    backgroundColor: theme.palette.grey[100],
  },
  AccordionActions: {
    display: "flex",
    justifyContent: "flex-start",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
const Layout1 = (props) => {
  const classes = useStyles();
  let { title } = useParams();

  const [data, setData] = React.useState(null);
  //    const [data, setData] = React.useState(null);
  React.useEffect(() => {
    Axios.get(`/data/FAQ.json`).then((res) =>
      setData(res.data.faqs.find((item) => item.urlcode === title))
    );
  }, []);

  console.log(data);
  const handleSocialClick = (url) => {
    window.open(url, "_blank");
  };
  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);

  return (
    <div className={classes.root}>
      <SeoComponent
        title={data?.title}
        description={data?.description}
        keywords={data?.keywords}
        image={data?.img}
      />
      <Container maxWidth="lg">
        {data !== null && data !== undefined && (
          <Grid container spacing={5} className={classes.container}>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <Typography variant="h4" color="primary">
                {data !== null && data.title}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <Typography variant="body1">
                {data !== null && ReactHtmlParser(data.description)}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              {data !== null &&
                data.sublist &&
                data.sublist.length > 0 &&
                data.sublist.map((item, index) => {
                  return (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          {item.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.description}</Typography>
                      </AccordionDetails>
                      <AccordionActions className={classes.AccordionActions}>
                        <Button>Learn More</Button>
                      </AccordionActions>
                    </Accordion>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default withRouter(Layout1);
