import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Divider } from "@material-ui/core";

import NewsCard from "./NewsCard";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "5vh 0vw",
    height: "60vh",
    [theme.breakpoints.down("md")]: {
      padding: "3vh 0vw",
      height: "auto",
    },
  },
  Divider: {
    backgroundColor: theme.palette.pink,
    height: ".1em",
  },
  Title: {
    marginLeft: theme.spacing(3),
    textTransform: "uppercase",
  },
  blueTetx: {
    color: theme.palette.darkBlue,
    fontSize: "2.5rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  pinkTetx: {
    color: theme.palette.pink,
    fontSize: "2.5rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  blackTetx: {
    color: theme.palette.black,
    fontSize: "1rem",
    fontWeight: 600,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.pink,
      textDecoration: "none",
    },
  },
  cardContainer: {
    margin: "3vh 0vh",
    [theme.breakpoints.down("md")]: {
      margin: "2vh 0vh",
    },
  },
}));
const ConsultancyContainer = (props) => {
  const classes = useStyles();
  const { data } = props;

  const { title, subTitle1, subTitle2, cards } = data;
  return (
    <div className={classes.main}>
      <Container maxWidth="lg">
        <Grid container className={classes.main}>
          <Grid
            item
            container
            alignItems="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Grid container direction="column">
              <Grid
                item
                container
                lg={12}
                md={12}
                sm={12}
                xs={12}
                alignItems="center"
                spacing={1}
              >
                <Grid item lg={1} md={2} sm={2} xs={2}>
                  <Divider className={classes.Divider} />
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                  <Typography className={classes.Title}>{title}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.blueTetx}>
                    {subTitle1}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.pinkTetx}>
                    {" "}
                    {subTitle2}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                justify="space-between"
                className={classes.cardContainer}
              >
                {cards.map((data, index) => (
                  <NewsCard data={data} key={index} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default ConsultancyContainer;
