import { utilTypes } from "../types";

export const openSecuredContactForm = (successpath, failpath) => {
  return {
    type: utilTypes.OPEN_SECURED_CONTACT_FORM,
    payload: { successpath, failpath },
  };
};
export const closeSecuredContactForm = () => {
  return {
    type: utilTypes.CLOSE_SECURED_CONTACT_FORM,
  };
};
export const submitContactForm = (data) => {
  return {
    type: utilTypes.SUBMIT_CONTACT_FORM,
    payload: {
      request: {
        method: "post",
        url: "/SFA/Query/Company/Contactus1",
        data: data,
      },
    },
  };
};
