import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  ExpansionPanel,
  Button,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Link, withRouter } from "react-router-dom";
import CheckIcon from "@material-ui/icons/CheckRounded";
import UncheckIcon from "@material-ui/icons/CloseRounded";
const useStyles = makeStyles((theme) => ({
  root: {},
  usecaseTitle: {
    fontWeight: 600,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  check: {
    color: "green",
  },
  unCheck: {
    color: "red",
  },
  AppBar: {
    backgroundColor: theme.palette.darkBlue,
  },
  activeTab: {
    backgroundColor: theme.palette.primary.main,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//        <TableBody>
//          {featurelist.map((item, index) => {
//            return (
//              <TableRow key={index}>
//                <TableCell>{item.feature}</TableCell>
//                <TableCell align="center">
//                  {item.standard ? (
//                    <CheckIcon className={classes.check} />
//                  ) : (
//                    <UncheckIcon className={classes.unCheck} />
//                  )}
//                </TableCell>
//                <TableCell align="center">
//                  {item.gold ? (
//                    <CheckIcon className={classes.check} />
//                  ) : (
//                    <UncheckIcon className={classes.unCheck} />
//                  )}
//                </TableCell>{" "}
//                <TableCell align="center">
//                  {item.platinum ? (
//                    <CheckIcon className={classes.check} />
//                  ) : (
//                    <UncheckIcon className={classes.unCheck} />
//                  )}
//                </TableCell>
//              </TableRow>
//            );
//          })}
//        </TableBody>



const PriceTable = (featurelist, plans) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Features</TableCell>
            {plans.map((item, index) => {
              return (
                <TableCell align="center" key={index}>
                  {item}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {featurelist.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{item.feature}</TableCell>
                <TableCell align="center">
                  {item.standard}
                </TableCell>
                <TableCell align="center">
                  {item.gold }
                </TableCell>{" "}
                <TableCell align="center">
                  {item.platinum }
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const ServiceTable = (featurelist, plans) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Features</TableCell>
            {plans.map((item, index) => {
              return (
                <TableCell align="center" key={index}>
                  {item}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {featurelist.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{item.service}</TableCell>
                <TableCell align="center">{item.standard}</TableCell>
                <TableCell align="center">{item.gold}</TableCell>{" "}
                <TableCell align="center">{item.platinum}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const Layout1 = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { data } = props;
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h6">{data.highllight}</Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <AppBar position="static" className={classes.AppBar}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              //   textColor=""
              variant="fullWidth"
              aria-label="simple tabs example"
            >
              <Tab
                className={value === 0 ? classes.activeTab : null}
                label={data.erplistTitle}
                {...a11yProps(0)}
              />
              <Tab
                className={value === 1 ? classes.activeTab : null}
                label={data.ecomlistTitle}
                {...a11yProps(1)}
              />
              <Tab
                className={value === 2 ? classes.activeTab : null}
                label={data.servicepackTitle}
                {...a11yProps(2)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Grid container justify="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {PriceTable(data.erplist, data.plans)}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container justify="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {PriceTable(data.ecomlist, data.plans)}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container justify="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {ServiceTable(data.servicepack, data.plans)}
              </Grid>
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Layout1);
