export default [
  {
    imageSrc:
      "https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80",
    title: "How we are",
    subTitle: "Service",
    description:
      "Business we operate in is like an intricate game of chess, where every move counts and you keep score with money.",
    button: { title: "Card1", link: "/card" },
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80",
    title: "we are",
    subTitle: "Service",
    description:
      "Business we operate in is like an intricate game of chess, where every move counts and you keep score with money.",
    button: { title: "Card2", link: "/card" },
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1444653614773-995cb1ef9efa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=755&q=80",
    title: "How we ",
    subTitle: "Service",
    description:
      "Business we operate in is like an intricate game of chess, where every move counts and you keep score with money.",
    button: { title: "Card3", link: "/card" },
  },
];
