export { default as Layout1 } from "./Layout1";
export { default as Layout2 } from "./Layout2";
export { default as Layout3 } from "./Layout3";
export { default as Layout4 } from "./Layout4";
export { default as CaseStudiesLayout } from "./CaseStudiesLayout";
export { default as DownloadsLayout } from "./DownloadLayout";

export { default as HowToLayout } from "./HowToLayout";
export { default as FaqLayout } from "./FaqLayout";
export { default as VideosLayout } from "./VideosLayout";
export { default as ArticlesLayout } from "./ArticlesLayout";
export { default as Layout7 } from "./Layout7";
export { default as Layout8 } from "./Layout8";
export { default as Layout9 } from "./Layout9";

export { default as BlogLayout } from "./BlogLayout";
