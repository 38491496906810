const Topbar = {
  links: [
    { title: "Careers", link: "careers" },
    { title: "Partners", link: "partners" },
    { title: "Support", link: "support" },
    { title: "Suppliers", link: "suppliers" },
  ],
  // officeTime: "Monday - Friday8AM - 9PM",
  // officeLocation: "Offices Bloomsbury Square, London WC1B 4EA",
  socialText: "Visit our social pages",
  socialLinks: {
    facebook: "https://Facebook.com",
    twitter: "https://twitter.com",
    pinterest: "https://pinterest.com",
    linkedIn: "https://linkedin.com",
  },
};
export default Topbar;
