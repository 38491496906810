import React from "react";
import {
  withRouter,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import Dividertext from "../Components/Common/DividerText";
import Layout1 from "../Layouts/Layout1";
import Axios from "axios";
import * as layouts from "../Layouts";
import { useDispatch } from "react-redux";
import { pushtohistory } from "../redux/action/historyActions";
import SEO from "@americanexpress/react-seo";
import SeoComponent from "../Components/Seo/SeoComponent";
const useStyles = makeStyles((theme) => ({
  Container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
}));
const SubCategory = (props) => {
  const { subcat } = useParams();
  const [data, setData] = React.useState([]);
  const linktitle = useHistory().location.state;
  console.log("Location", useLocation());
  //   const getLayoutComponent = (data) => {
  //     // switch (data.layouttype) {
  //     //   case "layout1":
  //     //     return <Layout1 data={data} />;
  //     //     break;
  //     //   default:
  //     //     break;
  //     // }
  //   };

  const classes = useStyles();
  const [layout, setLayout] = React.useState("Layout1");

  React.useEffect(() => {
    if (subcat === "Blog") {
      setLayout("BlogLayout");
    }
    if (subcat === "Videos") {
      setLayout("VideosLayout");
    }
    if (subcat === "CaseStudies") {
      setLayout("CaseStudiesLayout");
    }
    if (subcat === "HowTo") {
      setLayout("HowToLayout");
    }
    if (subcat === "FAQ") {
      setLayout("FaqLayout");
    }
    if (subcat === "WhitepapersandArticles") {
      setLayout("ArticlesLayout");
    }
    if (subcat === "Downloads") {
      setLayout("DownloadsLayout");
    }
    // switch (subcat) {
    //   case "Blog":
    //     setLayout("BlogLayout");

    //   case "CaseStudies":
    //     setLayout("CaseStudiesLayout");

    //   case "HowTo":
    //     setLayout("HowToLayout");

    //   case "Videos":
    //     setLayout("VideosLayout");

    //   default:
    //     // setLayout("Layout1");
    //     break;
    // }
  }, [subcat]);

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, [layout]);

  const Input = layouts[layout];

  return (
    <div className={classes.root}>
      <SeoComponent
      // title={data?.title}
      // description={data?.description}
      // keywords={data?.keywords}
      // image={data?.img}
      />
      <Container maxWidth="lg" className={classes.Container}>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            <Dividertext title={linktitle} />
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Input />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default withRouter(SubCategory);
