import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid } from "@material-ui/core";
import GetInTouch from "./GetInTouch";
import TouchCard from "./TouchCard";
const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      marginTop: theme.spacing(-5),
    },
  },
  content: {
    marginTop: theme.spacing(2),
  },

  cardContainer: {
    height: "30vh",
    width: "90%",
    position: "absolute",
    margin: "30% 5vw",
    zIndex: 500,
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "100%",
      position: "static",
      margin: "0% 0vw",
    },
  },
  frame: {
    [theme.breakpoints.down("md")]: {
      height: "50vh",
    },
  },
}));

const MapContainer = (props) => {
  const { mapLink, getinTouch, cards } = props.data;
  const classes = useStyles();

  const mobile = window.innerWidth < 1000 ? 0 : 1;
  return (
    <div className={classes.container}>
      <div className={classes.cardContainer}>
        <Card className={classes.card} elevation={mobile}>
          <CardContent>
            <Grid
              container
              justify="space-evenly"
              className={classes.gridContainer}
              alignItems="flex-end"
            >
              <GetInTouch data={getinTouch} />
              <Grid item lg={8} md={12} sm={12} xs={12}>
                <Grid container className={classes.content}>
                  {cards.map((data, index) => (
                    <Grid item lg={4} xs={12} md={12} sm={12} key={index}>
                      <TouchCard data={data} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>

      <iframe
        title="map"
        src={mapLink}
        width="100%"
        height="100%"
        className={classes.frame}
      ></iframe>
    </div>
  );
};
export default MapContainer;
