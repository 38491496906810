import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Paper,
  Box,
  Container,
} from "@material-ui/core";
import Chevronright from "@material-ui/icons/ChevronRight";
import { Link, withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {},
  usecaseTitle: {
    fontWeight: 600,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  description: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.darkBlue,
  },
  AccordionSummary: {
    backgroundColor: theme.palette.grey[100],
  },
  AccordionActions: {
    display: "flex",
    justifyContent: "flex-start",
  },
}));
const Layout1 = (props) => {
  const classes = useStyles();
  const { data } = props;
  const handlefaq = (title) => {
    props.history.push(`/FAQ/${title}`);
  };
  return (
    <div>
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          {/* {data.faqlist.map((study, index) => {
            return (
              <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                <Paper elevation={0}>
                  <Box m={1}>
                    <Typography variant="body1">{study.title}</Typography>
                  </Box>
                  <Divider style={{ width: 100 }} />
                  <Box m={1}>
                    <Typography variant="body2">{study.details}</Typography>
                  </Box>
                  <Button
                    endIcon={<Chevronright />}
                    color="primary"
                    variant="text"
                    onClick={() => handlefaq(study.question)}
                  >
                    Learn More
                  </Button>
                </Paper>
              </Grid>
            );
          })} */}
        </Grid>
      </Container>
    </div>
  );
};

export default withRouter(Layout1);
