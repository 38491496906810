import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
// import clock from "../../Static/sand-clock.png";
const useStyles = makeStyles((theme) => ({
  ExperienceCard: {
    height: "20vh",

    [theme.breakpoints.down("md")]: {
      height: "10vh",
      marginTop: "2vh",
    },
  },
  img: {
    width: "70%",
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      height: "10vh",
    },
  },
  blueTetx: {
    color: theme.palette.darkBlue,
    fontSize: "1.5rem",
    fontWeight: 800,
    textAlign: "center",
  },
  pinkTetx: {
    color: theme.palette.pink,
    fontSize: "2.5rem",
    fontWeight: 800,
  },
  blackTetx: {
    width: "95%",
    color: theme.palette.text.secondary,
    fontSize: "0.9rem",
    fontWeight: 400,
    textAlign: "center",
  },
}));
const ExperienceCard = ({ data }) => {
  const { imageSrc, title, description } = data;
  const classes = useStyles();
  return (
    <Grid
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      className={classes.ExperienceCard}
    >
      <Grid item container justify="center" lg={12} md={12} sm={12} xs={12}>
        <img src={imageSrc} className={classes.img} alt={title} />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        container
        alignItems="center"
        justify="center"
      >
        <Typography className={classes.blueTetx}>{title}</Typography>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        container
        alignItems="center"
        justify="center"
      >
        <Typography className={classes.blackTetx}>{description}</Typography>
      </Grid>
    </Grid>
  );
};
export default ExperienceCard;
