export default {
  data: [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80",
      title: "WHAT'S MISSING IN YOUR BUSINESS?",
      subtitleLine1: "Find that",
      subtitleLine2: "Missing Piece",
      description:
        "Nuncho Software is all about strategy, we’re here to inform which tactics need funding and which are drains on resources.",
      button1: { title: "View More Solution", link: "/button1" },
      button2: { title: "View team", link: "/button2" },
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1529400971008-f566de0e6dfc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80",
      title: "How's YOUR BUSINESS?",
      subtitleLine1: "Find that",
      subtitleLine2: "Missing Piece",
      description:
        "Nuncho Software is all about strategy, we’re here to inform which tactics need funding and which are drains on resources.",
      button1: { title: " Solution", link: "/button1" },
      button2: { title: "View team", link: "/button2" },
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80",
      title: "WHAT'S MISSING IN YOUR BUSINESS?",
      subtitleLine1: "Find that",
      subtitleLine2: "Missing Piece",
      description:
        "Nuncho Software is all about strategy, we’re here to inform which tactics need funding and which are drains on resources.",
      button1: { title: "More Solution", link: "/button1" },
      button2: { title: "View team", link: "/button2" },
    },
  ],
};
