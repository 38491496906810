import React from "react";
import TopNav from "./TopNav";
import MainNav from "./MainNav";

export default function Index(props) {
  return (
    <div>
      <TopNav data={props.topbar} />

      <MainNav data={props.mainbar} />
    </div>
  );
}
