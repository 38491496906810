import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Divider } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "100%",
  },
  gridItem: {},
  Divider: {
    backgroundColor: theme.palette.pink,
    height: ".1em",
  },
  Title: {
    marginLeft: theme.spacing(3),
    textTransform: "uppercase",
    fontSize: "1rem",
  },
  blueTetx: {
    color: theme.palette.darkBlue,
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  pinkTetx: {
    color: theme.palette.pink,
    fontSize: "1rem",
    fontWeight: 600,
  },
  blackTetx: {
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    fontWeight: 400,
  },
}));
const GetInTouch = ({ data }) => {
  const { title, subTitle, description } = data;
  const classes = useStyles();
  return (
    <Grid
      item
      lg={4}
      md={12}
      sm={12}
      xs={12}
      container
      className={classes.gridItem}
      alignItems="center"
      spacing={1}
    >
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <Divider className={classes.Divider} />
      </Grid>
      <Grid item lg={10} md={10} sm={10} xs={10}>
        <Typography className={classes.Title}>{title}</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.blueTetx}>{subTitle}</Typography>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.blackTetx}>{description}</Typography>
      </Grid>
    </Grid>
  );
};
export default GetInTouch;
